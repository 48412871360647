import { Injectable } from '@angular/core';
import { AnalyticsService } from 'ngx-shared-services';
import { GlobalNavService, User } from 'ngx-global-nav';

/**
 * A service to easily implement mixpanel tracking
 */
@Injectable({ providedIn: 'root' })
export class MixPanelService {
  EVENTS = {
    VIEW_PAGE: 'View Active Dispatches',

    CLICK_PERFORMANCE_TAB: "AD.ViewDispPerf",
    COLLAPSE_LEFT_MENU: "AD.CollapseLeftMenu",
    EXPAND_LEFT_MENU: "AD.ExpandLeftMenu",
    SORT_WORST_PERFORMANCE: 'AD.Sort_WorstPerformance',
    SORT_TARGET_REDUCTION: 'AD.Sort_TargetReduction',
    SORT_START_TIME: 'AD.Sort_StartTime',
    SORT_END_TIME: 'AD.Sort_EndTime',
    SELECT_SITE: 'AD.SelectSite',
    CLICK_PERFORMANCE_BANNER_MORE: 'AD.Banner_SeeMore',
    CLICK_PERFORMANCE_BANNER_LESS: 'AD.Banner_SeeLess',

    CLICK_SCHEDULE_TAB: 'AD.ViewDispSchedule',
  };

  APPLICATION_NAME = 'ActiveDispatches';

  isInit = false;
  user!: User;

  constructor(private analyticsService: AnalyticsService, private globalNavService: GlobalNavService) {
    this.globalNavService.userData$.subscribe((user) => {
      if (user && !this.isInit) {
        this.user = user;
        this.analyticsService.init(user.user_id, this.APPLICATION_NAME);
        this.isInit = true;
      }
    });
  }
  
  viewActiveDispatches(){
    this.analyticsService.pushEvent(this.EVENTS.VIEW_PAGE, { application: this.APPLICATION_NAME });
  }

  clickPerformanceTab() {
    if (this.user) {
      this.analyticsService.pushEvent(this.EVENTS.CLICK_PERFORMANCE_TAB, {});
    }
  }

  collapseLeftMenu() {
    if (this.user) {
      this.analyticsService.pushEvent(this.EVENTS.COLLAPSE_LEFT_MENU, {});
    }
  }

  expandLeftMenu() {
    if (this.user) {
      this.analyticsService.pushEvent(this.EVENTS.EXPAND_LEFT_MENU, {});
    }
  }

  changeSort(sortType) {
    if(this.user) {
      switch (sortType) {
        case 'byPerformanceOrProgramName':
          this.analyticsService.pushEvent(this.EVENTS.SORT_WORST_PERFORMANCE, {});
          break;
        case 'byCapacityOrProgramName':
          this.analyticsService.pushEvent(this.EVENTS.SORT_TARGET_REDUCTION, {});
          break;
        case 'byEventStart':
          this.analyticsService.pushEvent(this.EVENTS.SORT_START_TIME, {});
          break;
        case 'byEventEnd':
          this.analyticsService.pushEvent(this.EVENTS.SORT_END_TIME, {});
          break;
      }
    }
  }

  selectSite(site: string) {
    if (this.user) {
      this.analyticsService.pushEvent(this.EVENTS.SELECT_SITE, { site });
    }
  }

  clickPerformanceBannerMore() {
    if (this.user) {
      this.analyticsService.pushEvent(this.EVENTS.CLICK_PERFORMANCE_BANNER_MORE, {});
    }
  }

  clickPerformanceBannerLess() {
    if (this.user) {
      this.analyticsService.pushEvent(this.EVENTS.CLICK_PERFORMANCE_BANNER_LESS, {});
    }
  }

  clickScheduleTab() {
    if (this.user) {
      this.analyticsService.pushEvent(this.EVENTS.CLICK_SCHEDULE_TAB, {});
    }
  }
}