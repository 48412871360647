import { Pipe, PipeTransform } from '@angular/core';
import { Event } from '../models/event';
import { EventNode } from '../models/event-node';
import { PerformanceStatus } from '../models/shared';

export type DesiredTrait = Pick<Event & EventNode, 'progress_status' | 'performance'>;

const visibleBefore: PerformanceStatus[] = ['UNDER_PERFORMING', 'PERFORMING_NEAR_EXPECTATION', 'PERFORMING'];

@Pipe({ name: 'performanceShow' })
export class PerformanceShowPipe implements PipeTransform {

  transform(value: DesiredTrait): boolean {
    const { progress_status, performance: { status } } = value;
    switch (progress_status) {
      case 'BEFORE':
        return !!status && visibleBefore.includes(status);
      case 'DURING':
      case 'AFTER':
        return true;
      default:
        return false;
    }
  }

}
