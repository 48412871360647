import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-shared-services';
import { Table } from 'primeng/table';
import { DateTime } from 'luxon'

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit {

  @Input() nodes;

  @Input() scheduleStatuses = [];
  @ViewChild('dt1', {static: false}) nodesTable: Table;
  frozenColumns = [];
  tooltipOptions = {autoHide: false, hideOnEscape: false, escape: false, hideDelay: 1000000000000000000};
  locale;

  constructor(private translate: TranslateService, private cookieService: CookieService) { }

  ngOnInit(): void {
    this.locale = this.cookieService.getI18NLocale() || 'en-us';
  }

  getScheduleStatusLabel(status){
    switch (status){
      case 'COMPLETED':
      case 'OVERLAPPING':
        return this.translate.instant("DISPATCH_SCHEDULE.SCHEDULE_STATUS.COMPLETED");
      case 'ONGOING':
        return this.translate.instant("DISPATCH_SCHEDULE.SCHEDULE_STATUS.ONGOING");
      case 'UPCOMING':
        return this.translate.instant("DISPATCH_SCHEDULE.SCHEDULE_STATUS.UPCOMING");
      case 'STANDBY':
        return this.translate.instant("DISPATCH_SCHEDULE.SCHEDULE_STATUS.STANDBY");
    }
  }

  getLocalTime(dttm, timezone){
    if(!!dttm){
      const localDate = DateTime.fromISO(dttm).setZone(timezone);
      return localDate.setLocale(this.locale).toLocaleString(DateTime.DATETIME_MED) + ' ' + localDate.setLocale('en-us').toFormat('ZZZZ');
    } else {
      return this.translate.instant("DISPATCH-LIST.DISPATCH-CARD.WINDOW.NONE");
    }

  }


}
