// tslint:disable:max-line-length
import { isNull } from 'common-utils/dist/typescript-utils';
import { EventNode } from '../../../models/event-node';
import { MetaModel } from '../../../models/shared';
import { orNaN, orNull, orString } from '../../../models/shared/utils';
import { FormatService, toMoment } from '../../../services/format.service';
import * as moment from 'moment';
import { ApiTime } from 'common-utils/dist/models/time';

function isAfter(node: EventNode): node is EventNode & { event: { progress_status: 'AFTER' } } {
  return node.event.progress_status === 'AFTER';
}

function hasDate(node: EventNode): node is EventNode & { last_current_performance_dttm: Exclude<EventNode['last_current_performance_dttm'], null> } {
  return !!node.last_current_performance_dttm;
}

export class DashboardSummaryViewModel implements MetaModel<EventNode> {

  readonly header: {
    readonly primary: string;
    readonly secondary: string;
  };
  readonly tray: {
    readonly primary: {
      readonly title: string; // tray.primary.title === header.primary, see constructor
      readonly text: string;
    };
    readonly secondary: {
      readonly title: string;
      readonly text: string;
    }
  };

  readonly i18nPayload = {
    performance: {
      value:      orString(this.formatService.number(this.model.performance.value), '?'),
      delta:      orString(this.formatService.number(orNull(orNaN(this.model.expected_capacity.value) - orNaN(this.model.performance.value)), 1), '?'),
      percentage: orString(this.formatService.number(orNull(orNaN(Math.round(this.model.performance.percentage * 100))), 1), '?'),
      uom:        orString(this.model.performance.uom, '?'),
    },
    demand: {
      value:  orString(this.formatService.number(this.model.last_current_metered.value), '?'),
      uom:    orString(this.model.last_current_metered.uom, '?'),
    },
    capacity: {
      value:  orString(this.formatService.number(this.model.expected_capacity.value), '?'),
      uom:    orString(this.model.expected_capacity.uom, '?'),
    },
    fsl: {
      value:  orString(this.formatService.number(this.model.fsl.value), '?'),
      delta:  orString(this.formatService.number(orNull(orNaN(this.model.performance.value) - orNaN(this.model.fsl.value)), 1), '?'),
      uom:    orString(this.model.fsl.uom, '?'),
    },
    date: this.model.last_current_performance_dttm ? moment(this.model.last_current_performance_dttm.localDate).format('LT') : '',
    timezone: this.model.event.program_time_zone_abbr,
  };

  constructor(
    readonly model: EventNode,
    private formatService: FormatService,
  ) {
    this.header = {
      primary: getHeaderPrimary(this.model),
      secondary: getHeaderSecondary(this.model),
    };
    this.tray = {
      primary: {
        title: this.header.primary,
        text: getTrayPrimaryText(this.model),
      },
      secondary: {
        title: getTraySecondaryTitle(this.model),
        text: getTraySecondaryText(this.model),
      },
    };
  }

}

export function getHeaderPrimary(model: EventNode): string {
  return 'PERF.PRIMARY.' + ((isNull(model.event.progress_status)) ?
  'NO_DATA' :
  (model.event.progress_status === 'AFTER' && model.flags.is_fsl_indicator) ?
    'AFTER.IS_FSL' :
    (isNull(model.performance.status) || model.performance.status === 'ONLY_PERF_AVAILABLE' ||
    model.performance.status === 'ONLY_TARGET_AVAILABLE' || model.performance.status === 'NO_PERF_NUMBERS') ?
      'NO_DATA' :
      `${model.event.progress_status}.${model.performance.status}`);
}

export function getHeaderSecondary(model: EventNode): string {
  const isAfterKey = isAfter(model) ? 'AFTER' : 'NOT_AFTER';
  const hasDateKey = hasDate(model) ? 'TIMESTAMP' : 'NO_TIMESTAMP';
  return ((isNull(model.event.progress_status) || isNull(model.performance.status) || model.performance.status === 'NO_PERF_NUMBERS') ?
    'PERF.SECONDARY.NO_DATA' :
    (model.flags.is_fsl_indicator) ?
      'PERF.FSL.' + (() => {
        switch (model.performance.status) {
          case 'ONLY_TARGET_AVAILABLE':
            return 'ONLY_TARGET_AVAILABLE';
          case 'UNDER_PERFORMING':
          case 'PERFORMING_NEAR_EXPECTATION' :
          case 'ONLY_PERF_AVAILABLE':
            return `ONLY_PERF_AVAILABLE.${isAfterKey}`;
          case 'PERFORMING':
          default:
            return `PERFORMING.${isAfterKey}.${hasDateKey}`;
        }
      })() :
      'PERF.CBL.' +
        ((model.performance.status === 'ONLY_PERF_AVAILABLE' || model.performance.status === 'ONLY_TARGET_AVAILABLE') ?
          `${model.performance.status}.` : 'HAS_STATUS.') + `${isAfterKey}.${hasDateKey}`
);
}

export function getTrayPrimaryText(model: EventNode): string {
  const isAfterKey = isAfter(model) ? 'AFTER' : 'NOT_AFTER';
  return 'PERF.TRAY.' + (
    (isNull(model.event.progress_status) ||
    isNull(model.performance.status) ||
    model.performance.status === 'NO_PERF_NUMBERS' ||
    model.performance.status === 'ONLY_PERF_AVAILABLE' ||
    model.performance.status === 'ONLY_TARGET_AVAILABLE') ?
      model.estimate_performance_ind === true ? 'MISSING_DATA_NON_METERED' : 'MISSING_DATA' :
      `${isAfterKey}.${model.performance.status}`
  );
}

export function getTraySecondaryTitle(model: EventNode): string {
  return model.estimate_performance_ind === true ? '' : 'PERF.TRAY.' + ((isNull(model.event.progress_status) || isNull(model.performance.status)) ?
    'NEXT_STEPS.HEADER' :
    (model.performance.status === 'PERFORMING_NEAR_EXPECTATION' || model.performance.status === 'UNDER_PERFORMING') ?
      'RECOMMENDATION.HEADER' :
      'NEXT_STEPS.HEADER'
  );
}

export function getTraySecondaryText(model: EventNode): string {
  const isAfterKey = isAfter(model) ? 'AFTER' : 'NOT_AFTER';
  return model.estimate_performance_ind === true ? '' : 'PERF.TRAY.' + ((isNull(model.event.progress_status) || isNull(model.performance.status)) ?
    'NEXT_STEPS.ERROR' :
    (model.performance.status === 'PERFORMING_NEAR_EXPECTATION' || model.performance.status === 'UNDER_PERFORMING') ?
      `RECOMMENDATION.${isAfterKey}` :
      (model.performance.status === 'PERFORMING') ?
        'NEXT_STEPS.PERFORMING' :
        'NEXT_STEPS.ERROR'
  );
}
