import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ContactDetails } from '../../../models/contact-details';

@Component({
  selector: 'app-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.scss'],
})
export class ContactDetailsComponent implements OnInit, OnChanges{

  @Input() contacts: ContactDetails[];
  contactsScrollHeight;

  constructor() {}


  ngOnInit() {
  }

  ngOnChanges(changes:SimpleChanges){
    if(changes.contacts?.currentValue?.length){
      if(changes.contacts?.currentValue?.length > 30){
        this.contactsScrollHeight = '500px'
      } else {
        this.contactsScrollHeight = null;
      }
    }
  }
}
