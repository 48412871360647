export interface Connection {
  client_id: string;
  endpoint_url: string;
  expires_seconds: number;
  topic: string;
}

export interface ConnectionRenew {
  client_id: string;
  endpoint_url: string;
  expires_seconds: number;
}

export function compare(a: Connection, b: Connection): boolean {
  return a.client_id === b.client_id && a.topic === b.topic; // TODO check if endpoint_url matters, seems to change every time on renew
}
