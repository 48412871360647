import {Inject, Injectable} from '@angular/core';
import * as MobileDetect from 'mobile-detect';
import * as nsWebViewInterface from 'nativescript-webview-interface/www/nativescript-webview-interface';
import {CookieService} from 'ngx-shared-services';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';

/**
 * Interface for native mobile application
 */
@Injectable({ providedIn: 'root' })
export class NativeService {

  private ns = nsWebViewInterface; // force ns script to run
  private mobileDetector = new MobileDetect((this.window as any).navigator.userAgent);
  private _hasNSWrapper: boolean;
  private nativeLoadSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(@Inject('WINDOW') private window: Window, private cookieService: CookieService) {

    if (this.isMobileDevice()) {
      (this.window as any).nsWebViewInterface.on('loaded', () => {
        this._hasNSWrapper = true;
        this.nativeLoadSubject.next(true);
      });

      (this.window as any).nsWebViewInterface.emit('webapp_loaded');
    }
  }

  get hasNSWrapper(): boolean {
    return this._hasNSWrapper;
  }

  get onNativeLoadSubject(): BehaviorSubject<boolean> {
    return this.nativeLoadSubject;
  }

  isMobileDevice(): boolean {
    return !!this.mobileDetector.mobile() || this.cookieService.getCookie('test_mobile') === 'true';
  }

  handleLogout(): void {
    (this.window as any).nsWebViewInterface.emit('logout');
    console.log('emit native logout');
  }
}
