
<app-toast-messages></app-toast-messages>
<div [hidden]="isMobile">
  <global-nav [navUrl]="navUrl"></global-nav>
</div>
<div style="background: #ffffff;">
  <div class="section-header">{{ 'APP.TITLE' | translate }}</div>
  <div class="nav-tabs">
    <div *ngIf="locale=='en-us'" [ngClass]="{'nav-tab': true, 'on-page': currentRoute?.includes('schedule')}"><a [routerLink]="[routeBrand + '/schedule']" (click)="clickOnTab('schedule')">{{ 'DISPATCH_SCHEDULE.TITLE' | translate }}</a></div>
    <div *ngIf="locale=='en-us'" [ngClass]="{'nav-tab': true, 'on-page': !currentRoute?.includes('schedule')}"> <a [routerLink]="[routeBrand + '/performance']" (click)="clickOnTab('performance')">{{ 'DISPATCH_PERFORMANCE.TITLE' | translate }}</a></div>
  </div>
</div>


<div style="min-height: calc(100% + 40px);">
  <router-outlet></router-outlet>
  <div style="margin-top: -40px;"><global-footer></global-footer></div>
</div>


