import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { Event } from '../models/event';
import { NativeService } from './native.service';
import { DispatchExpandService } from './dispatch-expand.service';

@Injectable({
  providedIn: 'root'
})
export class EventListService {

  events;
  selectedNode = '';
  eventsExpanded = {};
  nodesColumnSort = {};

  sortedEvents$ = new ReplaySubject<Event[]>();

  constructor(private nativeService: NativeService, private dispatchExpandService: DispatchExpandService) { }

  setEventExpanded(eventId, exp) {
    this.eventsExpanded[eventId] = exp;
  }

  getEventExpanded(eventId) {
    return this.eventsExpanded[eventId] ? this.eventsExpanded[eventId] : false;
  }

  setSelectedNode(nodeId) {
    this.selectedNode = nodeId;
  }

  getNodeSelected(nodeId) {
    this.selectedNode === nodeId;
  }

  setNodesColumnSort(eventId, column, direction) {
    this.nodesColumnSort[eventId] = {name: column, direction: direction};
  }

  getNodesColumnSort(eventId) {
    if(this.nodesColumnSort[eventId]) {
      return this.nodesColumnSort[eventId]
    } else {
      return {name:'performance', direction: 'up'}
    }
  }
}
