<div class="card-container" [ngClass]="highlightedClass$ | async" (click)="onClick()" (onHover)="onHover($event)">
  <span *ngIf="performance.show" class="percent" [ngClass]="performance.class">{{ performance.label }}</span>

  <div class="details">
    <span ellipsisTooltip>{{ program }}</span>
    <span ellipsisTooltip>{{ sites }}</span>
    <span ellipsisTooltip>{{ reductionVal }}</span>
    <span ellipsisTooltip>{{ startAndEndTime }}</span>
    <span ellipsisTooltip *ngIf="event.event_end_dttm_utc">{{ timeFromNow }}</span>
  </div>
  <span *ngIf="chevron.show && !fetchingNodes" class="fa fa-chevron-{{ chevron.direction }}"></span>
  <span *ngIf="fetchingNodes" class="fa fa-spinner"></span>
</div>
<app-nodes-list *ngIf="expanded" [eventNodes]="nodes"  [event] = event></app-nodes-list>

