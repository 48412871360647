import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { NativeService } from '../../services/native.service';
import { DispatchExpandService } from '../../services/dispatch-expand.service';

@Component({
  selector: 'app-dispatch-performance-view',
  templateUrl: './dispatch-performance-view.component.html',
  styleUrls: ['./dispatch-performance-view.component.scss']
})
export class DispatchPerformanceViewComponent implements OnInit {

  readonly expanded$: Observable<boolean>;
  readonly isMobile: boolean;

  constructor(private nativeService: NativeService,private dispatchExpandService: DispatchExpandService) {
    this.isMobile = this.nativeService.isMobileDevice();
    // globalnav
    // this.eventsService.getEvents();
    this.expanded$ = this.dispatchExpandService.expanded$;
  }

  ngOnInit(): void {
  }

}
