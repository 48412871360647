import { Injectable } from '@angular/core';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _user:User;
  constructor() { }

  get user(): User {
    return this._user;
  }

  set user(val:User) {
    this._user = val;
  }

  formatUser(user_details):void {
    const usr = new User();
    usr.brand = user_details.brand;
    usr.first_name = user_details.profile.names.first_name;
    usr.last_name = user_details.profile.names.last_system_name;
    usr.default_locale = user_details.preferences.default_locale;
    usr.default_unit_system = user_details.preferences.default_unit_system;
    usr.default_time_zone = user_details.preferences.default_time_zone;
    usr.username = user_details.username;
    usr.user_id = user_details.user_id;

    this.user = usr;
  }
}
