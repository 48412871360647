<p-toast key="connection-error" position="top-center">
  <ng-template let-message pTemplate="message">
    <div class="connection-error-body">
      <i class="pi pi-ban connection-error-icon"></i>
      <span>{{ 'TOASTER.CONNECTION-ERROR' | translate }}</span>
    </div>
  </ng-template>
</p-toast>

<p-toast key="no-nodes" position="top-center">
  <ng-template let-message pTemplate="message">
    <div class="connection-error-body">
      <i class="pi pi-ban connection-error-icon"></i>
      <span>{{ 'TOASTER.NO-NODES-ERROR' | translate }}</span>
    </div>
  </ng-template>
</p-toast>
