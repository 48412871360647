<div class="dispatch-schedule-view">

  <div *ngIf="fetchingData" class="load-bar">
    <indeterminate-load-bar></indeterminate-load-bar>
  </div>

  <div *ngIf="!fetchingData">
    <div class="schedule-container">
      <p-panel [toggleable]="true" iconPos="start">
        <ng-template pTemplate="header">
          <span class="panel-header">
            <div class="schedule-header">{{ 'DISPATCH_SCHEDULE.TODAYS_SCHEDULE' | translate }}</div>
            <div class="dispatches">{{ 'DISPATCH_SCHEDULE.DISPATCHES' | translate }} <span class="nodes-length"> {{todayNodes.length}}</span></div>
          </span>
        </ng-template>
        <div *ngIf="!todayNodes.length" class="no-nodes">
          {{ 'DISPATCH_SCHEDULE.NO_NODES_TODAY' | translate }}
        </div>
        <app-schedule [nodes]="todayNodes" [scheduleStatuses]="scheduleStatuses" *ngIf="todayNodes.length"></app-schedule>
      </p-panel>
    </div>

    <!--FUTURE-->

    <div class="schedule-container">
      <p-panel [toggleable]="true" [collapsed]="true">
        <ng-template pTemplate="header">
          <span class="panel-header">
            <div class="schedule-header">{{ 'DISPATCH_SCHEDULE.FUTURE_SCHEDULE' | translate }}</div>
            <div class="dispatches">{{ 'DISPATCH_SCHEDULE.DISPATCHES' | translate }} <span class="nodes-length"> {{futureNodes.length}}</span></div>
          </span>
        </ng-template>
        <app-schedule [nodes]="futureNodes"></app-schedule>
      </p-panel>
    </div>
  </div>
</div>

