import { Registration } from './registration';
import { Event } from './event'
export interface EventNodeAPI {

  activation_time: string;
  alt_id:string;
  average_performance_percentage: number;
  average_performance_status: string;
  average_performance_uom:string;
  average_performance_value: number;
  bonus_uom: string;
  bonus_value: number;
  cancelled:string;
  cancelled_no_restore:string;
  cancelled_with_restore:string;
  confirmed:string;
  preauthorized: string;
  created_dttm: string;
  curtail_complete: string;
  curtail_exception_detected:string;
  curtail_exception_resolved:string;
  curtail_included: boolean;
  curtail_time: string;
  registration_display_labels;
  registration_display_label: string;
  event_id: string;
  event_node_end_dttm_utc: string;
  event_node_end_time: string;
  event_node_id: string;
  event_node_start_dttm_utc: string;
  event_node_start_time: string;
  expected_capacity_uom: string;
  expected_capacity_value: number;
  firm_service_level_uom: string;
  firm_service_level_value: number;
  follow_up_notif_included:boolean;
  follow_up_notif_time:string;
  initial_notif_included: boolean;
  initial_notif_time: string;
  is_fsl_indicator: boolean;
  is_generator_indicator:boolean;
  last_current_metered_uom:string;
  last_current_metered_value:number;
  last_current_performance_dttm_program_locale:string;
  last_current_performance_dttm_utc:string;
  last_current_performance_percentage:number;
  last_current_performance_status:string;
  last_current_performance_uom:string;
  last_current_performance_value:number;
  last_notification_sent: string;
  last_notification_sent_dttm: string;
  last_updated_dttm:string;
  manual_trip_time:string;
  max_end_program_offset_min:string;
  opted_out:string;
  participation_plan_id:string;
  portfolio_id: string;
  product_id:string;
  registered_capacity_uom:string;
  registered_capacity_value: number;
  registration_id: string;
  remove_from_workflow:string;
  restore_complete:string;
  restore_exception_detected:string;
  restore_exception_resolved:string;
  restore_included: boolean;
  restore_notif_included: boolean;
  restore_notif_time: string;
  restore_time: string;
  restore_trip_notif_included:boolean;
  restore_trip_notif_time:string;
  restore_trip_time:string;
  site_display_label;
  site_id: string;
  trip_complete:string;
  trip_exception_detected:string;
  trip_exception_resolved:string;
  trip_restore_offset:string;
  tripped_notif_included:boolean;
  tripped_notif_time:string;
  ufr_workflow:string;
  workflow_completed:string;
  workflow_status: string;
  device_link: string;
  realtime_availability: number;
  workflow_status_display_label: string;
  last_notification_sent_display_label:string;
  control_type:string;
  site_name:string;
  assignee: string;
  organization_display_label:string;
  opted_out_reason: string;
  opted_out_user_id: string;
  registration_type: string;
  registration_type_display_label:string;
  adjustment_window_start: string;
  adjustment_window_end: string;
  notes;
  expected_capacity: number;
  estimate_performance_ind: boolean;
  control_type_display_label:string;
  minimum_performance_value: number;
  minimum_performance_percentage: number;
  minimum_performance_uom: string;
  maximum_performance_value: number;
  maximum_performance_percentage: number;
  maximum_performance_uom: string;
  performance_start_dttm_utc: string;
  performance_end_dttm_utc: string;
  note_details: NoteDetails;


  //This stuff is for the event detail page

}

export interface EventNode extends EventNodeAPI{
  event: Event;
  registration: Registration;
  locale: string;
  scheduleStatus:string;
  pre_event_buffer: number;
  post_event_buffer: number;
  performance_value: number;
  timezone:string;

}

export interface EventNodeNote {
  event_node_id: string;
  registration_id: string;
  site_id: string;
  event_id: string;
  note_id: string;
  created_by: string;
  note_dttm: string;
  case_type: string;
  note_text: string;
  expiration_dttm: string;
  deleted: boolean
}

export interface LatestNote {
  note_id: string;
  created_by: string;
  note_dttm: string;
  last_updated_dttm: string;
  case_type: string;
  note_text: string;
  expiration_dttm: string;
  deleted: string;
}

export interface NoteDetails {
  event_node_id: string;
  count: number;
  latest_note: LatestNote;
}

export enum  RegistrationType {
  LOAD = 'LOAD', GENERATOR = 'GENERATOR', STORAGE = 'STORAGE', DEMAND_ON = 'DEMAND_ON', LOAD_METERED_GENERATOR = 'LOAD_METERED_GENERATOR',
}
export enum  EventNodeStatusForSort {
  UPCOMING = 'Upcoming', ONGOING = 'Ongoing', COMPLETED = 'Completed'
}
export enum  StatusColor {
  UPCOMING = 'LOAD', ONGOING = 'GENERATOR', COMPLETED = 'STORAGE'
}
