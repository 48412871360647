import { Injectable } from '@angular/core';
import { KeyPerformanceMetric } from '../models/event';
import { EventAPI, Event } from '../models/interfaces/event'
import { EventNodeAPI, EventNode } from '../models/interfaces/event-node';
import { Product } from '../models/product';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor() { }
}

export function getOverallPerformance(event: Event) {
  let perfValue;
  const perfMetric = event.product ? event.product.key_performance_metric :  KeyPerformanceMetric.AVERAGE;
  switch (perfMetric) {
    case KeyPerformanceMetric.AVERAGE:
      perfValue = event.average_performance_value;
      break;
    case  KeyPerformanceMetric.MAXIMUM:
      perfValue = event.non_coincident_maximum_performance_value;
      break;
    case KeyPerformanceMetric.MINIMUM:
      perfValue = event.non_coincident_minimum_performance_value;
      break;
  }
  return perfValue;
}

export function getOverallNodePerformance(node: EventNode, product:Product) {
  let perfValue;
  const perfMetric = product ? product.key_performance_metric :  KeyPerformanceMetric.AVERAGE;
  switch (perfMetric) {
    case KeyPerformanceMetric.AVERAGE:
      perfValue = node.average_performance_value;
      break;
    case  KeyPerformanceMetric.MAXIMUM:
      perfValue = node.maximum_performance_value;
      break;
    case KeyPerformanceMetric.MINIMUM:
      perfValue = node.minimum_performance_value;
      break;
  }
  return perfValue;
}

export function getOverallNodePerformancePercentage(node: EventNode) {
  const expectedCapacity = node.expected_capacity_value;
  const performanceVal = getOverallNodePerformance(node, node.event.product);
  const performancePerCent = expectedCapacity === 0 ? 0 : Math.round(performanceVal / expectedCapacity * 100);
  return performancePerCent;
}
