import { isNull, WithProperties } from 'common-utils/dist/typescript-utils';

export type Parser<T, U> = (target: T, prefix: string) => U;

/** adds the underscore to prefix if need be */
export function curryPrefix<T, U>(fn: Parser<T, U>): Parser<T, U> {
  return (target: T, prefix: string) => {
    prefix = (prefix === '' || prefix.endsWith('_')) ? prefix : `${prefix}_`;
    return fn(target, prefix);
  };
}

/** generates a parsing function for the given fields, with given return type */
export function parseFactory<T extends object>(...fields: string[]) {
  return curryPrefix<any, T>((target: any, prefix: string): T => {
    return fields.reduce((a, field) => {
      const value = target[`${prefix}${field}`];
      const boxed = {} as T;
      boxed[field] = value;
      return { ...(a as object), ...(boxed as object) } as T;
    }, {} as T);
  });
}

/** thin try/catch wrapper with optional return value should shit hit the fan */
export function tryParse<T, V = null>(fn: () => T, value: V | null = null): T | V | null {
  try { return fn(); } catch (e) { return value; }
}

export function normalise<T extends WithProperties<{ [key: string]: any }, number | null>>(
  key: keyof T,
  normFn: (number: number) => number,
  input: T,
): T {
  const value = input[key];
  const normalised = (isNull(value)) ? value : normFn(value);
  input[key] = normalised as T[keyof T];
  return input;
}

export function orNaN(d: number | null): number { return (isNull(d)) ? NaN : d; }
export function orNull(d: number): number | null { return (isNaN(d)) ? null : d; }
/** If no `defaultValue` is specified, defaults to empty string */
export function orString(d: string | null, defaultValue = ''): string { return (isNull(d)) ? defaultValue : d; }
