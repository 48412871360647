import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, shareReplay } from 'rxjs/operators';
import { EventNode } from '../models/event-node';

@Injectable({ providedIn: 'root' })
export class DispatchSelectService {

  readonly selected$: Observable<EventNode | null>;
  private readonly _selected$ = new ReplaySubject<EventNode | null>(1);

  constructor() {
    this.selected$ = this._selected$.pipe(
      distinctUntilChanged((a, b) => a === b || !!a && !!b && a.id === b.id),
      shareReplay(1),
    );
  }

  select(node: EventNode | null): void {
    this._selected$.next(node);
  }

}
