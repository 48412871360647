import { animate, group, style, transition, trigger } from '@angular/animations';
import {Component, OnInit} from '@angular/core';
import { Observable, of } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import { Event } from '../../../models/event';
import { EventNode } from '../../../models/event-node';
import { DispatchSelectService } from '../../../services/dispatch-select.service';
import { FormatService } from '../../../services/format.service';
import { NativeService } from '../../../services/native.service';
import { DashboardSummaryViewModel } from './dashboard-summary.view-model';
import { EventsApiService } from '../../../services/events-api.service';
import { MixPanelService } from 'src/app/services/mixpanel.service';

@Component({
  selector: 'app-dashboard-summary',
  templateUrl: './dashboard-summary.component.html',
  styleUrls: ['./dashboard-summary.component.scss'],
  animations: [
    trigger('toggle', [
      transition(':enter', [
        style({ 'max-height': 0, 'padding': 0 }),
        group([
          animate('200ms ease-out', style({ padding: '20px 30px' })),
          animate('500ms ease-out', style({ 'max-height': 500 })),
        ]),
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ 'height': 0, 'padding-top': 0, 'padding-bottom': 0, 'padding-right': 0, 'padding-left': 0 })),
      ]),
    ]),
    trigger('fade', [
      transition('* => *', [
        style({ opacity: 0 }),
        animate('200ms ease-out', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class DashboardSummaryComponent {

  readonly noData$: Observable<boolean>;
  readonly isMobile: boolean;
  viewModel$: Observable<DashboardSummaryViewModel>;
  expanded = false;
  event: Event;
  selectedNode;

  constructor(
    private dispatchSelectService: DispatchSelectService,
    private formatService: FormatService,
    private nativeService: NativeService,
    private eventService: EventsApiService,
    private mixpanelService: MixPanelService,
  ) {
    this.isMobile = this.nativeService.isMobileDevice();
    const { events$ } = this.eventService;
    const { selected$ } = this.dispatchSelectService;
    const { eventNodeUpdate$ } = this.eventService;
    this.noData$ = events$.pipe(
      map((events) => !events.length),
      shareReplay(1),
    );

    selected$.subscribe(
      (node) => {
        this.selectedNode = node;
        this.viewModel$ = of(new DashboardSummaryViewModel(node, this.formatService));
      }
    );

   eventNodeUpdate$.subscribe((eventNode) => {
      if(eventNode && this.selectedNode && eventNode.id === this.selectedNode.id){
        this.viewModel$ = of(new DashboardSummaryViewModel(eventNode, this.formatService));
      }
    });

   /*  events$.subscribe((event) => {
      this.viewModel$ = selected$.pipe(
        filter<EventNode>((node) => !!node),
        map((node) => new DashboardSummaryViewModel(this.latestDataForSelectedNode(event, node), this.formatService)),
        shareReplay(1),
      );
    });*/
  }

  toggle(): void {
    this.expanded = !this.expanded;
    if(this.expanded) {
      this.mixpanelService.clickPerformanceBannerMore();
    } else {
      this.mixpanelService.clickPerformanceBannerLess();
    }
  }

/*  latestDataForSelectedNode(eventData: Event[], selectedNode: EventNode): EventNode {
    return eventData.filter((a) => a.id === selectedNode.event.id).map((a) => a.event_nodes)[0].filter((a) => a.id === selectedNode.id)[0];
  }*/
}
