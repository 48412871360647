<h1 class="header">{{ 'DASHBOARD.CONTACTS.HEADER' | translate }}</h1>
<div *ngIf="!contacts?.length" class="description">{{ 'DASHBOARD.CONTACTS.NO_CONTACTS' | translate  }}</div>

<div class="contact-card" *ngIf="contacts?.length">
  <p-scrollPanel [style]="{height: contactsScrollHeight }">
    <table class="contact-info-table">
      <thead>
        <tr>
          <td></td>
          <td>{{ 'DASHBOARD.CONTACTS.TITLE' | translate }}</td>
          <td>{{ 'DASHBOARD.CONTACTS.PHONE' | translate }}</td>
          <td>{{ 'DASHBOARD.CONTACTS.EMAIL' | translate }}</td>
          <td>{{ 'DASHBOARD.CONTACTS.PRIMARY' | translate }}</td>
          <td>{{ 'DASHBOARD.CONTACTS.TWENTY_FOUR_SEVEN' | translate }}</td>
        </tr>
      </thead>

      <tbody>
        <tr  *ngFor="let contact of contacts">
          <td>
            <b>{{contact.full_name}}</b>
          </td>
          <td>{{contact.title}}</td>
          <td>
            <span>
              <span style="white-space: nowrap" *ngIf="contact.phone"> &nbsp;({{contact.phone.country_code}}) {{contact.phone.phone_number}} </span>
              <span *ngIf="!contact.phone"> &nbsp; - </span>
            </span>
          </td>
          <td>
            <span *ngIf="contact.email">
              <a href="mailto:{{contact.email.email_address}}">{{contact.email.email_address}}</a>
            </span>
          </td>
          <td style="text-align: center;">
            <img *ngIf="!!contact.primary_contact" src="/assets/icons/checkmark.svg" class="icon-checkmark"/>
          </td>
          <td style="text-align: center;">
            <img *ngIf="!!contact.twentyfour_seven_contact" src="/assets/icons/checkmark.svg" class="icon-checkmark"/>
          </td>
        </tr>
      </tbody>
    </table>

  </p-scrollPanel>
<!--  <h2>{{ contact.contact_name }}</h2>
  <div class="row" *ngIf="!!contact.phone1 && !!contact.phone1_ext">
    <span>{{ 'DASHBOARD.CONTACTS.PHONE' | translate }}</span>&nbsp;
    <a href="tel:{{contact.phone1}}">{{ 'DASHBOARD.CONTACTS.PHONE_WITH_EXT' | translate:{phone: contact.phone1, ext: contact.phone1_ext} }}</a>
  </div>
  <div class="row" *ngIf="!!contact.phone1 && !contact.phone1_ext">
    <span>{{ 'DASHBOARD.CONTACTS.PHONE' | translate }}</span>&nbsp;
    <a href="tel:{{contact.phone1}}">{{ contact.phone1 }}</a>
  </div>
  <div class="row" *ngIf="!!contact.cell">
    <span>{{ 'DASHBOARD.CONTACTS.MOBILE' | translate }}</span>&nbsp;
    <a href="tel:{{contact.cell}}">{{ contact.cell }}</a>
  </div>
  <div class="row">
    <span>{{ 'DASHBOARD.CONTACTS.ROLE' | translate }}</span>&nbsp;
    <span *ngIf="contact.is_authorised">{{ 'DASHBOARD.CONTACTS.AUTHORISED' | translate }}</span>
    <span *ngIf="!contact.is_authorised">{{ 'DASHBOARD.CONTACTS.NOT_AUTHORISED' | translate }}</span>
  </div>
  <div class="row">
    <span>{{ 'DASHBOARD.CONTACTS.EMAIL' | translate }}</span>&nbsp;
    <a href="mailto:{{contact.email}}">{{ contact.email }}</a>
  </div>-->
</div>
