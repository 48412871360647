<app-dashboard-metadata></app-dashboard-metadata>
<app-dashboard-summary></app-dashboard-summary>
<indeterminate-load-bar *ngIf="!dataLoaded"></indeterminate-load-bar>
<ng-container *ngIf="dataLoaded">
  <div>
    <app-graph-interval-data [viewModel]="viewModel$ | async"></app-graph-interval-data>
    <div [ngClass]="(isMobile)?'mobile-dashboard-bottom':'dashboard-bottom'">
      <app-reduction-plan [ngClass]="(isMobile)?'mobile-erp-margin':'erp-margin'" [plan]="reductionPlan$ | async"></app-reduction-plan>
      <app-contact-details [ngClass]="(isMobile)?'mobile-contact-details':'contact-details'" [contacts]="contactDetails$ | async"></app-contact-details>
    </div>
  </div>

</ng-container>
