import { Environment } from './environment.interface';
export { Environment } from './environment.interface';

export const environment: Environment = {
  production: false,
  environment: "dev",
  brands: {
    consumersenergy: {
      emsUrl: 'https://event-management-api.dev-apps.enelx.net',
      baseUrl: 'https://api.eps.dev-apps.enelx.net/v1',
      globalNavUrl: 'https://global-nav-v2.dev-svcs.enernoc.net/api/v2',
    },
    enelx: {
      emsUrl: 'https://event-management-api.dev-apps.enelx.net',
      baseUrl: 'https://api.eps.dev-apps.enelx.net/v1',
      globalNavUrl: 'https://global-nav-v2.dev-svcs.enernoc.net/api/v2',
    },
    demo: {
      emsUrl: 'https://event-management-api.dev-apps.enelx.net',
      baseUrl: 'https://api.eps.dev-apps.enelx.net/v1',
      globalNavUrl: 'https://global-nav-v2.dev-svcs.enernoc.net/api/v2',
    },
  },
  cdn: 'https://cdn.dev-apps.enelx.net',
  defaults: {
    brand: 'enelx',
    locale: 'en-us',
  },
};
