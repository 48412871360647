export type Accessor<A, B> = (a: A) => B;
export type Comparator<T> = (a: T, b: T) => number;

export type SimpleComparable = string | number | Date;
export const simpleComparator: Comparator<SimpleComparable> = (a, b) => (a > b) ? 1 : (a < b) ? -1 : 0;

export function factory<A, B>(accessor: Accessor<A, B>, comparator?: Comparator<B>): Comparator<A> {
  comparator = comparator ? comparator : simpleComparator as Comparator<any> as Comparator<B>; // TODO can do better
  return (a, b) => comparator!(accessor(a), accessor(b));
}

export function composer<A>(head: Comparator<A>, ...tail: Array<Comparator<A>>): Comparator<A> {
  return tail.reduce((aggregate, comparator) => (a, b) => aggregate(a, b) || comparator(a, b), head);
}

export function inverter<A>(comparator: Comparator<A>, direction: 1 | -1): Comparator<A> {
  return (a, b) => comparator(a, b) * direction;
}
