import { Injectable } from '@angular/core';
import { DomainAwarenessService } from 'ngx-shared-services';
import { environment } from '../../environments/environment';
import { APIEndpoints } from '../../environments/environment.interface';

@Injectable({ providedIn: 'root' })
export class ApiEndpointsService{

  private readonly endpoints: APIEndpoints;

  constructor(
    private domainAwarenessService: DomainAwarenessService,
  ) {
    const { brand } = this.domainAwarenessService;
    if (brand === undefined) {
      throw new Error('Unable to infer brand from domain');
    } else {
      this.endpoints = environment.brands[brand] || environment.brands[environment.defaults.brand];
    }
  }

  get emsUrl(): string {
    return this.endpoints.emsUrl;
  }
  get baseUrl(): string {
    return this.endpoints.baseUrl;
  }

  get globalNavUrl(): string {
    return this.endpoints.globalNavUrl;
  }
}
