<div class="header">
  <div class="column performance" (click)="sortBy('performance')">
    <span>
      {{ 'DISPATCH-LIST.SITES-LIST.PERFORMANCE' | translate }}
      <i *ngIf="sortedColumn.name == 'performance'" class="fa fa-chevron-{{ sortedColumn.direction }}"></i>
    </span>
  </div>
  <div class="column performance" (click)="sortBy('location')">
    <span>
      {{ 'DISPATCH-LIST.SITES-LIST.LOCATION' | translate }}
      <i *ngIf="sortedColumn.name == 'location'" class="fa fa-chevron-{{ sortedColumn.direction }}"></i>
    </span>
  </div>
  <div class="column performance" (click)="sortBy('reduction')">
    <span>
      {{ 'DISPATCH-LIST.SITES-LIST.REDUCTION' | translate }}
      <i *ngIf="sortedColumn.name == 'reduction'" class="fa fa-chevron-{{ sortedColumn.direction }}"></i>
    </span>
  </div>

</div>

<app-nodes-list-item *ngFor="let node of eventNodes" [eventNode]="node">

</app-nodes-list-item>
