import { Component, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, GlobalNavService } from 'ngx-global-nav';
import { AnalyticsService, CookieService } from 'ngx-shared-services';
import { combineLatest, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { ApiEndpointsService } from './services/api-endpoints.service';
import { DispatchExpandService } from './services/dispatch-expand.service';
import { DispatchSelectService } from './services/dispatch-select.service';
import { EventsApiService } from './services/events-api.service';
import { NativeService } from './services/native.service';
import { UserService } from './services/user.service';
import { MixPanelService } from './services/mixpanel.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  readonly expanded$: Observable<boolean>;
  readonly isMobile: boolean;
  navUrl = '';
  theme: string;
  defaultBrand = 'enelx';
  currentRoute: string;
  locale;
  routeBrand = '';

  constructor(
    private nativeService: NativeService,
    private analytics: AnalyticsService,
    private translate: TranslateService,
    private cookieService: CookieService,
    private apiService: ApiEndpointsService,
    private authService: AuthService,
    private globalNavService: GlobalNavService,
    private dispatchExpandService: DispatchExpandService,
    private dispatchSelectService: DispatchSelectService,
    private router: Router,
    private userService: UserService,
    private eventsService: EventsApiService,
    private mixpanelService: MixPanelService,
  ) {
    this.isMobile = this.nativeService.isMobileDevice();
    // globalnav
    // this.eventsService.getEvents();
    this.expanded$ = this.dispatchExpandService.expanded$;

    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
        const {
          queryParamMap: { params },
        } = this.router.parseUrl(event.url) as any;
      }
    });
    this.getNavUrl();
  }

  private getNavUrl(): void {
    const { globalNavUrl } = this.apiService;
    this.navUrl = globalNavUrl;
  }

  public clickOnTab(tabName: 'performance' | 'schedule'): void {
    if(tabName === 'performance') {
      this.mixpanelService.clickPerformanceTab()
    } else {
      this.mixpanelService.clickScheduleTab()
    }
  }

  ngOnInit() {
    // i18n - cobranding stuff
    const { defaults } = environment;
    this.mixpanelService.viewActiveDispatches();
    this.locale = this.cookieService.getI18NLocale() || defaults.locale;
    const brand = this.cookieService.getI18NBrand() || defaults.brand.toUpperCase();
    if(brand === 'CONSUMERSENERGY') this.routeBrand = 'consumersenergy'
    this.translate.setDefaultLang(`${defaults.locale}.${defaults.brand.toUpperCase()}`);
    this.translate.use(`${this.locale}.${brand}`);

    this.globalNavService.userData$.subscribe((user) => {
      if(user !== null) {
        this.userService.formatUser(user);
        this.analytics.init(user.user_id, 'Active Dispatches');
        this.analytics.setPage('Active Dispatches');
        this.analytics.pushEvent('View Active Dispatches',  {});
      }
    });

  }
}
