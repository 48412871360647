import { ApiTime } from 'common-utils/dist/models/time';
import { WithNulls } from 'common-utils/dist/typescript-utils';
import { EventNode, EventNodeAPI } from './event-node';
import { Product } from './product';
import {
  composer as comparatorComposer,
  factory as comparatorFactory, MetaModel,
  ProgressStatus,
  WithId,
  WithPerformancePercent,
  WithShortName,
  WithUomValue,
} from './shared';
import { orNaN, orString } from './shared/utils';

export interface EventAPI {
  event_id: string;
  operator_id: string;
  program_id: string;
  product_id: string;
  // `program_name` is indexed with posix locale when coming from EPS but gets processed in the presentation layer
  // see https://github.bdap.enernoc.net/HoneyBadgers/event-performance-dashboard/blob/6320cf2b4668c9755ef24fc6982296551078d896/api/src/Services/LocationService.js
  program_name: string;
  program_name_short: { [posixLocale: string]: string } | null;
  program_time_zone_abbr: string;
  event_performance_percent: number | null;
  event_progress_status: string | null;
  sum_expected_capacity_value: number | null;
  sum_expected_capacity_uom: string | null;
  average_performance_value: number | null;
  average_performance_uom: string | null;
  average_performance_status: string | null;
  last_current_performance_uom: string | null;
  last_current_performance_value: number | null;
  last_current_performance_status: string | null;
  num_participating_units: number;
  deleted: boolean;
  event_nodes: EventNodeAPI[];
  event_start_dttm_locale: string | null;
  event_start_dttm_utc: string | null;
  event_end_dttm_locale: string | null;
  event_end_dttm_utc: string | null;
  full_time_zone: string;
  source_system_type: string;
  product: Product;
  notification_time_utc: string | null;
  event_node_statistics: EventNodeStatistics;
  non_coincident_maximum_performance_value: number;
  non_coincident_minimum_performance_value: number;
}

export interface Event extends MetaModel<EventAPI>, WithId{
  id: string;
  program: WithId & WithShortName;
  program_time_zone_abbr: string;
  progress_status: ProgressStatus | null;
  performance: WithNulls<WithUomValue & WithPerformancePercent>;
  sum_expected_capacity: WithNulls<WithUomValue>;
  event_nodes: EventNode[];
  event_start: ApiTime | null;
  event_end: ApiTime | null;
  product: Product | null;
  source_system_type: string;
  notification_time_utc: string | null;
  len_total_event_nodes: number;
  len_active_event_nodes: number;
  len_pending_event_nodes: number;
  len_opted_out_event_nodes: number;
  site_display_label: string;
  event_start_dttm_utc: string;
  event_end_dttm_utc: string;
  full_time_zone: string;
  non_coincident_maximum_performance_value: number;
  non_coincident_minimum_performance_value: number;
}

/**
 * Here is where we can validate our assumptions on the incoming data
 */
export function healthCheck(raw: EventAPI): boolean {

  const validPerformanceStatus = (() => {
    const progress = raw.event_progress_status;
    const hasFinished = progress === 'AFTER';
    const value = (hasFinished) ? raw.average_performance_value : raw.last_current_performance_value;
    const status = (hasFinished) ? raw.average_performance_status : raw.last_current_performance_status;
    const hasValue = !!value; // covers for 0, undefined, null and NaN
    const hasCapacity = !!raw.sum_expected_capacity_value; // covers for 0, undefined, null and NaN
    switch (status) {
      case 'UNDER_PERFORMING':
      case 'PERFORMING_NEAR_EXPECTATION':
      case 'PERFORMING':
        return hasValue && hasCapacity;
      case 'NO_PERF_NUMBERS':
      case 'ONLY_TARGET_AVAILABLE':
      case 'ONLY_PERF_AVAILABLE':
        return !(hasValue && hasCapacity);
      default:
        return false;
    }
  })();

  const healthyNumericalValues = (() => {
    const numericalValues = [
      raw.event_performance_percent,
      raw.sum_expected_capacity_value,
      raw.average_performance_value,
      raw.last_current_performance_value,
    ];
    return numericalValues.every((number) =>  number !== null && number !== undefined && !isNaN(number));
  })();

  const consistentUoms = (() => {
    const uoms = [
      raw.sum_expected_capacity_uom,
      raw.average_performance_uom,
      raw.last_current_performance_uom,
    ];
    return new Set(uoms).size === 1;
  })();

  const dateFormat = (() => {
    // see https://github.bdap.enernoc.net/CommonPrezComponents/common-utils/blob/master/src/models/time/api-time.ts
    const regexes = [ ApiTime.re1, ApiTime.re2 ];
    const dates = [ raw.event_start_dttm_locale, raw.event_end_dttm_locale ];
    return dates.filter((date) => date).every((date) => regexes.some((regex) => regex.test(orString(date))));
  })();

  const report = {
    validPerformanceStatus,
    healthyNumericalValues,
    consistentUoms,
    dateFormat,
  };
  const allGood = Object.values(report).reduce((a, b) => a && b, true);
  if (!allGood) { console.warn('Event data health check failed', report, raw); }
  return allGood;
}

const compareByProgramName = comparatorFactory<Event, string>(({ program: { name } }) => name);
const compareByPerformance = comparatorFactory<Event, number>(({ performance: { percentage } }) => orNaN(percentage));
const compareByCapacity = comparatorFactory<Event, number>(({ sum_expected_capacity: { value } }) => orNaN(value));

export enum SourceSystem {CLASSIC_DR = 'CLASSIC_DR', EPS_API = 'EPS_API'}

export const comparators = {
  byPerformanceOrProgramName: comparatorComposer(compareByPerformance, compareByProgramName),
  byCapacityOrProgramName: comparatorComposer(compareByCapacity, compareByProgramName),
  byEventStart: comparatorFactory<Event, number>(({ event_start }) => (event_start) ? event_start.time : NaN),
  byEventEnd: comparatorFactory<Event, number>(({ event_end }) => (event_end) ? event_end.time : NaN),
};

export interface EventNodeStatistics {
  total_event_nodes: number;
  active_event_nodes: number;
  pending_event_nodes: number;
  opted_out_event_nodes: number;
  start_dttm_utc: string;
  end_dttm_utc: string;
  site_display_label: object;
  expected_capacity_value: number;
  registered_capacity_value: number;
}

export enum KeyPerformanceMetric {AVERAGE = 'AVERAGE', MINIMUM = 'MINIMUM', MAXIMUM = 'MAXIMUM'}
