import { Component, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DispatchScheduleService } from '../../services/dispatch-schedule.service';
import { Event } from '../../models/interfaces/event';
import { EventNode } from '../../models/interfaces/event-node';
import { DateTime } from 'luxon'
import { flattenDisplayLabel } from '../../services/format.service';
import { CookieService } from 'ngx-shared-services';
import { getOverallNodePerformancePercentage } from '../../services/common.service'
import { Table } from 'primeng/table';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dispatch-schedule-view',
  templateUrl: './dispatch-schedule-view.component.html',
  styleUrls: ['./dispatch-schedule-view.component.scss']
})
export class DispatchScheduleViewComponent implements OnInit {
  @ViewChild('todayTable', {static: false}) todayTable: Table;

  fetchingData = true;
  private ngUnsubscribe: Subject<any> = new Subject();
  scheduleStatuses = [];

  constructor(private DSAS: DispatchScheduleService, private cookieService: CookieService, private translate: TranslateService) {

    const {eventNodes$} = this.DSAS;
    eventNodes$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (response) => {
        this.allEventNodes = response;
        this.groupNodes();
      }
    );

  }
  todayNodes: EventNode[] = [];
  futureNodes: EventNode[] = [];
  allEventNodes: EventNode[] = [];
  eventsMap;
  locale;


  ngOnInit(): void {
    this.DSAS.getEvents();
    this.locale = this.cookieService.getI18NLocale() || 'en-us';
  }

  ngOnDestroy(){
    this.DSAS.clear();
    this.ngUnsubscribe.next();
  }

  setTranslations(){
    this.scheduleStatuses = [
      {label: this.translate.instant("DISPATCH_SCHEDULE.SCHEDULE_STATUS.COMPLETED"), value: 'COMPLETED'},
      {label: this.translate.instant('DISPATCH_SCHEDULE.SCHEDULE_STATUS.ONGOING'), value: 'ONGOING'},
      {label: this.translate.instant('DISPATCH_SCHEDULE.SCHEDULE_STATUS.COMPLETED'), value: 'OVERLAPPING'},
      {label: this.translate.instant('DISPATCH_SCHEDULE.SCHEDULE_STATUS.UPCOMING'), value: 'UPCOMING'},
    ];
  }

  groupNodes(){
    this.setTranslations()
    this.futureNodes = [];
    this.todayNodes = [];

    this.allEventNodes.forEach((node)=>{
      node.site_name = flattenDisplayLabel(this.locale, node.site_display_label);
      const productTimezone = node.event.product?.timezone;
      const regTimezone = node.registration?.timezone;
      node.timezone = productTimezone;
      node.performance_value = this.getPerformance(node)

      const relativeNow = DateTime.now().setZone(node.timezone);
      const relativeToday = DateTime.now().startOf('day').setZone(node.timezone);
      const relativeTomorrow = DateTime.now().plus({days:1}).startOf('day').setZone(node.timezone);
      const localNodeStart = DateTime.fromISO(node.event_node_start_dttm_utc).setZone(node.timezone);

      if(node.event.event_action_type === 'STANDBY' && localNodeStart < relativeToday){
        return;
      }

      if(localNodeStart > relativeToday){
        //UPCOMING
        if(localNodeStart > relativeTomorrow){
          if(node.event.event_action_type === 'STANDBY'){
            node.scheduleStatus = 'STANDBY'
          } else {
            node.scheduleStatus = 'UPCOMING'
          }
          this.futureNodes.push(node)
        } else {
          //STANDBY
          if(node.event.event_action_type === 'STANDBY'){
            node.scheduleStatus = 'STANDBY'
          }
          //ASSET ACTIVE IN ANOTHER DISPATCH
          else if(node.workflow_status == 'IN_OVERLAPPING_EVENT'){
            node.scheduleStatus = 'OVERLAPPING'
          }
          //COMPLETED
          else if(node.workflow_status == 'RESTORE_COMPLETE' || node.workflow_status == 'WORKFLOW_COMPLETE'){
            node.scheduleStatus = 'COMPLETED'
          }
          //UPCOMING
          else if(localNodeStart > relativeNow){
            node.scheduleStatus = 'UPCOMING'
          }
          //ONGOING
          else{
            node.scheduleStatus = 'ONGOING'
          }

          this.todayNodes.push(node)
        }
      }
    });
    this.fetchingData = false

  }

  getPerformance(node){
    const product = node.event.product;
    if(node.scheduleStatus == 'COMPLETED' ){
      return getOverallNodePerformancePercentage(node)
    } else {
      return (node.last_current_performance_percentage || 0)
    }
  }
}
