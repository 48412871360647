import { inject, Injectable, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterModule,
  RouterStateSnapshot,
  Routes
} from '@angular/router';
import { DispatchScheduleViewComponent } from './components/dispatch-schedule-view/dispatch-schedule-view.component';
import { AppComponent } from './app.component';
import { DispatchPerformanceViewComponent } from './components/dispatch-performance-view/dispatch-performance-view.component';
import { CookieService } from 'ngx-shared-services';


export const localeAuthGuard = () => {
  const router = inject(Router);
  const cookies = document.cookie.split(';');

  cookies.forEach((cookie)=>{
    let name = cookie.split('=')[0].trim();
    let value = cookie.split('=')[1].trim();
    if(name == 'locale'){
      if(value == 'en_US'){
        return true;
      } else {
        return router.parseUrl('/performance');
      }
    }
  })

};


const routes: Routes = [
  { path: '',  redirectTo: '/performance', pathMatch: 'full' },
  { path: 'performance', component: DispatchPerformanceViewComponent },
  { path: 'schedule', component: DispatchScheduleViewComponent, canActivate: [localeAuthGuard]},
  { path: 'consumersenergy/schedule', component: DispatchScheduleViewComponent, canActivate: [localeAuthGuard]},
  { path: 'consumersenergy', component: DispatchPerformanceViewComponent },
  { path: 'consumersenergy/performance', component: DispatchPerformanceViewComponent },
  { path: '**', redirectTo: '/'},
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
