<ng-container *ngIf="isMobile && (noData$ | async)"><ng-container *ngTemplateOutlet="mobile_header"></ng-container></ng-container>
<ng-container *ngIf="!isMobile || (isMobile && !(noData$ | async))"><ng-container *ngTemplateOutlet="header"></ng-container></ng-container>


<ng-template #header>
  <div class="icon-toggle" [class.active]="active$ | async" [class.inactive]="!(active$ | async)" (click)="toggle()"></div>
  <span>{{ 'DISPATCH_PERFORMANCE.LIST_TITLE' | translate }}</span>
</ng-template>

<ng-template #mobile_header>
  <span class="title-no-data">{{ 'DISPATCH_PERFORMANCE.LIST_TITLE' | translate }}</span>
</ng-template>
