class User {
  first_name: string;
  last_name: string;
  default_locale: string;
  default_unit_system: string;
  default_time_zone: string;
  username:string;
  user_id:string;
  brand:string;

  constructor() { }
}

export { User };
