<ng-container *ngIf="isMobile">
  <div *ngIf="viewModel$ | async as viewModel"><ng-container *ngTemplateOutlet="mobileWithData; context: { $implicit: viewModel }"></ng-container></div>
  <div *ngIf="noData$ | async"><ng-container *ngTemplateOutlet="mobileWithoutData"></ng-container></div>
</ng-container>

<ng-container *ngIf="!isMobile">
  <div *ngIf="viewModel$ | async as viewModel"><ng-container *ngTemplateOutlet="withData; context: { $implicit: viewModel }"></ng-container></div>
  <div *ngIf="noData$ | async"><ng-container *ngTemplateOutlet="withoutData"></ng-container></div>
</ng-container>

<ng-template #withData let-viewModel>
  <div class="col summary">

    <div class="row message-bar" (click)="toggle()" [ngClass]="viewModel.model | performanceClass">
      <div class="row message">
        <span>{{ viewModel.header.primary | translate }}</span>&nbsp;
        <span>{{ viewModel.header.secondary | translate:viewModel.i18nPayload }}</span>
      </div>
      <div class="col collapse-control" [@fade]="expanded">
        <span>{{ ('PERF.CONTROLS.' + (expanded ? 'LESS' : 'MORE')) | translate }}</span>
        <span class="fa fa-chevron-{{ expanded ? 'up' : 'down' }}"></span>
      </div>
    </div>

    <div *ngIf="expanded" class="row collapse-message" [@toggle]>
      <div class="col">
        <span class="tray-primary tray-title">{{ viewModel.tray.primary.title | translate }}</span>
        <span class="tray-primary tray-text">{{ viewModel.tray.primary.text | translate }}</span><br/>
        <span class="tray-secondary tray-title">{{ viewModel.tray.secondary.title | translate }}</span>
        <span class="tray-secondary tray-text">{{ viewModel.tray.secondary.text | translate }}</span><br/>
        <span class="tray-disclaimer">{{ 'PERF.TRAY.DISCLAIMER' | translate }}</span>
      </div>
      <div class="col well">
        <span><i class="fa fa-info-circle"></i>&nbsp;{{ 'ngx-global-contact-footer.GlobalFooter.EmailText' | translate }}</span><br/>
        <a href="mailto:{{ 'ngx-global-contact-footer.GlobalFooter.EmailLink' | translate }}">{{ 'ngx-global-contact-footer.GlobalFooter.EmailLink' | translate }}</a>
        <a href="tel:{{ 'ngx-global-contact-footer.GlobalFooter.PhoneLink' | translate }}">{{ 'ngx-global-contact-footer.GlobalFooter.PhoneText' | translate }}</a>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #withoutData>
  <div class="col summary">
    <div class="row message-bar performance-wut">
      <div class="row message">
        <span>{{ 'PERF.TRAY.NO_DATA.MESSAGE' | translate }}</span>
      </div>
    </div>
    <div class="row collapse-message">
      <div class="col">
        <span class="tray-primary tray-title">{{ 'PERF.TRAY.NO_DATA.TITLE' | translate }}</span>
        <span class="tray-primary tray-text">{{ 'PERF.TRAY.NO_DATA.TEXT' | translate }}</span><br/>
        <span class="tray-disclaimer">{{ 'PERF.TRAY.DISCLAIMER' | translate }}</span>
      </div>
      <div class="col well">
        <span><i class="fa fa-info-circle"></i>&nbsp;{{ 'ngx-global-contact-footer.GlobalFooter.EmailText' | translate }}</span><br/>
        <a href="mailto:{{ 'ngx-global-contact-footer.GlobalFooter.EmailLink' | translate }}">{{ 'ngx-global-contact-footer.GlobalFooter.EmailLink' | translate }}</a>
        <a href="tel:{{ 'ngx-global-contact-footer.GlobalFooter.PhoneLink' | translate }}">{{ 'ngx-global-contact-footer.GlobalFooter.PhoneText' | translate }}</a>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #mobileWithData let-viewModel>
  <div class="col mobile-summary">
    <div class="row mobile-message-bar" (click)="toggle()" [ngClass]="viewModel.model | performanceClass">
      <div class="row mobile-message">
        <span class="mobile-primary">{{ viewModel.header.primary | translate }}</span>
        <span class="mobile-secondary">{{ viewModel.header.secondary | translate:viewModel.i18nPayload }}</span>
      </div>
      <div class="col collapse-control" [@fade]="expanded">
        <span>{{ ('PERF.CONTROLS.' + (expanded ? 'LESS' : 'MORE')) | translate }}</span>
        <span class="fa fa-chevron-{{ expanded ? 'up' : 'down' }}"></span>
      </div>
    </div>
    <div *ngIf="expanded" class="row collapse-message" [@toggle]>
      <div class="col">
        <span class="tray-primary tray-title">{{ viewModel.tray.primary.title | translate }}</span>
        <span class="tray-primary tray-text">{{ viewModel.tray.primary.text | translate }}</span><br/>
        <span class="tray-secondary tray-title">{{ viewModel.tray.secondary.title | translate }}</span>
        <span class="tray-secondary tray-text">{{ viewModel.tray.secondary.text | translate }}</span><br/>
        <div class="col well">
        <span><i class="fa fa-info-circle"></i>&nbsp;{{ 'ngx-global-contact-footer.GlobalFooter.EmailText' | translate }}</span><br/>
          <a href="mailto:{{ 'ngx-global-contact-footer.GlobalFooter.EmailLink' | translate }}">{{ 'ngx-global-contact-footer.GlobalFooter.EmailLink' | translate }}</a>
          <a href="tel:{{ 'ngx-global-contact-footer.GlobalFooter.PhoneLink' | translate }}">{{ 'ngx-global-contact-footer.GlobalFooter.PhoneText' | translate }}</a>
        </div>
        <span class="mobile-tray-disclaimer">{{ 'PERF.TRAY.DISCLAIMER' | translate }}</span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #mobileWithoutData>
  <div class="col mobile-summary">
    <div class="row mobile-message-bar performance-wut">
      <div class="row mobile-message">
        <span>{{ 'PERF.TRAY.NO_DATA.MESSAGE' | translate }}</span>
      </div>
    </div>
    <div class="row collapse-message">
      <div class="col">
        <span class="tray-primary tray-text">{{ 'PERF.TRAY.NO_DATA.TEXT' | translate }}</span><br/>
        <div class="col well">
          <span><i class="fa fa-info-circle"></i>&nbsp;{{ 'PERF.TRAY.SUPPORT_TEXT' | translate }}</span><br/>
          <a href="mailto:{{ 'ngx-global-contact-footer.GlobalFooter.EmailLink' | translate }}">{{ 'ngx-global-contact-footer.GlobalFooter.EmailLink' | translate }}</a>
          <a href="tel:{{ 'ngx-global-contact-footer.GlobalFooter.PhoneLink' | translate }}">{{ 'ngx-global-contact-footer.GlobalFooter.PhoneText' | translate }}</a>
        </div>
        <span class="mobile-tray-disclaimer">{{ 'PERF.TRAY.DISCLAIMER' | translate }}</span>
      </div>
    </div>
  </div>
</ng-template>
