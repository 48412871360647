import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isNull } from 'common-utils/dist/typescript-utils';
import * as moment from 'moment';
import { Event } from '../models/event';
import { EventNode } from '../models/event-node';

export type DesiredTrait = Pick<Event & EventNode, 'progress_status' | 'event_start' | 'event_end'>;

type TimeUnits = 'days' | 'hours' | 'minutes';

@Pipe({ name: 'eventTimer' })
export class EventTimerPipe implements PipeTransform {

  constructor(
    private translateService: TranslateService,
  ) {}

  transform(value: DesiredTrait): string | null {
    const { event_start, event_end, progress_status } = value;

    if (isNull(event_start) || isNull(event_end) || isNull(progress_status)) {
      return null;
    }

    const now = moment();
    const start = moment(event_start.fullDate);
    const end = moment(event_end.fullDate);

    const compare = (progress_status === 'BEFORE') ? start : end;

    const [ days, hours, minutes ] = ([ 'days', 'hours', 'minutes' ] as TimeUnits[])
    .map((unit) => Math.abs(Math.round(moment.duration(compare.diff(now)).as(unit))));

    let timeUnits: TimeUnits;
    let timeValue: number;
    if (hours > 48) {
      timeUnits = 'days';
      timeValue = days;
    } else if (minutes > 59) {
      timeUnits = 'hours';
      timeValue = hours;
    } else {
      timeUnits = 'minutes';
      timeValue = minutes;
    }

    const i18nKey = `DISPATCH-LIST.DISPATCH-CARD.TIMER.${progress_status}.${timeUnits.toUpperCase()}`;
    const i18nValue = this.translateService.instant(i18nKey, { timeValue });
    return i18nValue;
  }

}
