import { Pipe, PipeTransform } from '@angular/core';
import { isNull } from 'common-utils/dist/typescript-utils';
import { Event } from '../models/event';
import { EventNode } from '../models/event-node';
import { PerformanceStatus } from '../models/shared';

/**
 * These colour bins refer to the classes in the [performance class style](../../styles/performance-colour-classes.scss)
 */
export type ColourBin = 'bad' | 'meh' | 'gud' | 'wut';

export type DesiredTrait = Pick<Event & EventNode, 'performance'>;

const classMap: { [key in PerformanceStatus]: ColourBin } = {
  UNDER_PERFORMING: 'bad',
  PERFORMING_NEAR_EXPECTATION: 'meh',
  PERFORMING: 'gud',
  NO_PERF_NUMBERS: 'wut',
  ONLY_TARGET_AVAILABLE: 'wut',
  ONLY_PERF_AVAILABLE: 'wut',
};

@Pipe({ name: 'performanceClass' })
export class PerformanceClassPipe implements PipeTransform {

  transform(value: DesiredTrait, light = false): string {
    const { performance: { status } } = value;
    const bin = (isNull(status)) ? 'wut' : classMap[status];
    return `performance-${bin}${(light) ? '-light' : ''}`;
  }

}
