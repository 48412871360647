

    <p-table #dt1
             [value]=" nodes"
             sortMode="single"
             [scrollable]="true"
             scrollHeight="400px"
             rowGroupMode="subheader"
             groupRowsBy="site_id"
             sortField="event_node_start_dttm_utc"
             [frozenColumns]="[{field: 'site_name'}]"
             [sortOrder]="1"
             [globalFilterFields]="['site_name']">

      <ng-template pTemplate="header">
        <tr class="header-template-row">
          <th  class="first-cell"></th>
          <th  pSortableColumn="site_name">{{ 'DISPATCH_SCHEDULE.SITE_NAME' | translate }} <p-sortIcon field="site_name"></p-sortIcon></th>
          <th *ngIf="scheduleStatuses.length> 1"  pSortableColumn="scheduleStatus">{{ 'DISPATCH_SCHEDULE.EVENT_STATUS' | translate }}<p-sortIcon field="scheduleStatus"></p-sortIcon></th>
          <th *ngIf="scheduleStatuses.length < 2" >{{ 'DISPATCH_SCHEDULE.EVENT_STATUS' | translate }}</th>
          <th pSortableColumn="event.program_name">{{ 'DISPATCH_SCHEDULE.PROGRAM' | translate }} <p-sortIcon field="event.program_name"></p-sortIcon></th>
          <th pSortableColumn="event_node_start_dttm_utc">{{ 'DISPATCH_SCHEDULE.START_TIME' | translate }} <p-sortIcon field="event_node_start_dttm_utc"></p-sortIcon></th>
          <th pSortableColumn="event_node_end_dttm_utc">{{ 'DISPATCH_SCHEDULE.END_TIME' | translate }} <p-sortIcon field="event_node_end_dttm_utc"></p-sortIcon></th>
          <th pSortableColumn="expected_capacity_value">{{ 'DISPATCH_SCHEDULE.TARGET_REDUCTION' | translate }} <p-sortIcon field="expected_capacity_value"></p-sortIcon></th>
          <th pSortableColumn="performance_value">{{ 'DISPATCH_SCHEDULE.PERFORMANCE' | translate }} <p-sortIcon field="performance_value"></p-sortIcon></th>
          <th class="last-cell"></th>
        </tr>
<!--        <tr class="filter-row">
          <th class="first-cell"></th>
          <th >
            <input pInputText type="text" (input) = "dt1.filter($event.target.value, 'site_name', 'contains')">
          </th>
          <th>
            <p-multiSelect *ngIf="scheduleStatuses.length > 1" (onChange)="dt1.filter($event.value, 'scheduleStatus', 'in')" [options]="scheduleStatuses" placeholder="{{'DISPATCH_SCHEDULE.SCHEDULE_STATUS.ALL'  | translate}}"></p-multiSelect>
          </th>
          <th>
            <input pInputText type="text" (input) = "dt1.filter($event.target.value, 'event.program_name', 'contains')">
          </th>
          <th colspan="5"></th>
        </tr>-->

      </ng-template>

      <ng-template pTemplate="groupheader" let-node>
        <tr class="group-header">
          <td class="left-cap"></td>
          <td colspan="7">{{node.site_name}}</td>
          <td class="right-cap"></td>
        </tr>

      </ng-template>

      <ng-template pTemplate="body" let-node let-i="rowIndex">
        <tr class="node-row">
          <td class="first-cell"></td>
          <td class="blank-space"></td>
          <td class="schedule-status"><div class="poodle"><div class="fa fa-circle {{node.scheduleStatus}}" aria-hidden="true"></div>&nbsp;{{ getScheduleStatusLabel(node.scheduleStatus) }}</div><span *ngIf="node.scheduleStatus=='OVERLAPPING'"  pTooltip="{{ 'DISPATCH_SCHEDULE.SCHEDULE_STATUS.TOOLTIP_OVERLAPPING'  | translate }}" [tooltipOptions]="tooltipOptions"> <img src="/assets/icons/alert.svg" class="icon-alert"/></span></td>

          <td>{{ node.event.program_name }}</td>
          <td class="start-time">{{ getLocalTime(node.event_node_start_dttm_utc, node.timezone)}}</td>
          <td class="end-time">{{ getLocalTime(node.event_node_end_dttm_utc, node.timezone)  }}</td>
          <td>{{ node.expected_capacity_value | number: '1.0-2' : locale }} kW</td>
          <td>{{ node.performance_value | number: '1.0-2' : locale}} %</td>
          <td class="last-cell"></td>
        </tr>
      </ng-template>

      <ng-template pTemplate="groupfooter">
        <tr>
          <td colspan="9" class="group-footer-border"></td>
        </tr>
        <tr>
          <td colspan="9" class="group-footer-spacer"></td>
        </tr>
      </ng-template>

    </p-table>
