import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, shareReplay, startWith, take } from 'rxjs/operators';
import { MixPanelService } from './mixpanel.service';

@Injectable({ providedIn: 'root' })
export class DispatchExpandService {

  readonly animating$ = new ReplaySubject<boolean>(1);

  readonly expanded$: Observable<boolean>;
  private readonly _expanded$ = new ReplaySubject<boolean>(1);

  constructor(private mixpanelService: MixPanelService) {
    this.expanded$ = this._expanded$.pipe(
      // startWith(false),
      startWith(true),
      distinctUntilChanged(),
      shareReplay(1),
    );
   }

  toggle(expand?: boolean): void {
    if (expand !== undefined) {
      this._expanded$.next(expand);
      if (expand) {
        this.mixpanelService.expandLeftMenu();
      } else {
        this.mixpanelService.collapseLeftMenu();
      }
    } else {
      this.expanded$.pipe(take(1)).subscribe((expanded) => {
        if(expanded) {
          this.mixpanelService.collapseLeftMenu();
        } else {
          this.mixpanelService.expandLeftMenu();
        }
        this._expanded$.next(!expanded)
      });
    }
  }

}
