import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon'
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-shared-services';

type Referential = 'relative' | 'absolute';
@Pipe({
  name: 'eventWindow'
})
export class EventWindowPipe implements PipeTransform {

  locale;
  constructor(private translateService: TranslateService, private cookieService: CookieService) {}

  transform(start, end, timezone, tzShort, referential: Referential): string {
    this.locale = this.cookieService.getI18NLocale();
    if(!!timezone && !!this.locale) {
      if (!end) {
        return this.translateService.instant('DISPATCH-LIST.DISPATCH-CARD.WINDOW.START', {
          date: this.getDayLabel(start, timezone, this.locale, referential),
          start: DateTime.fromISO(start).setZone(timezone).setLocale(this.locale).toLocaleString(DateTime.TIME_SIMPLE),
          timezone: DateTime.fromISO(start).setZone(timezone).setLocale('en-us').toFormat('ZZZZ')
        })
      } else {
        let val = this.translateService.instant('DISPATCH-LIST.DISPATCH-CARD.WINDOW.BOTH', {
          date: this.getDayLabel(start, timezone, this.locale, referential),
          end: DateTime.fromISO(end).setZone(timezone).setLocale(this.locale).toLocaleString(DateTime.TIME_SIMPLE),
          start: DateTime.fromISO(start).setZone(timezone).setLocale(this.locale).toLocaleString(DateTime.TIME_SIMPLE),
          timezone: DateTime.fromISO(start).setZone(timezone).setLocale('en-us').toFormat('ZZZZ')
        });
        return this.translateService.instant('DISPATCH-LIST.DISPATCH-CARD.WINDOW.BOTH', {
          date: this.getDayLabel(start, timezone, this.locale, referential),
          end: DateTime.fromISO(end).setZone(timezone).setLocale(this.locale).toLocaleString(DateTime.TIME_SIMPLE),
          start: DateTime.fromISO(start).setZone(timezone).setLocale(this.locale).toLocaleString(DateTime.TIME_SIMPLE),
          timezone: DateTime.fromISO(start).setZone(timezone).setLocale('en-us').toFormat('ZZZZ')
        })
      }
    }
    return ''
  }

  getDayLabel(dttm, timezone, locale, referential: Referential): string{
    if(!!timezone){
      const localDate = DateTime.fromISO(dttm).setZone(timezone);
      if(referential === 'absolute'){
        return localDate.setLocale(locale).toFormat('DD');
      } else {
        const relativeYesterday = DateTime.now().minus({days:1}).startOf('day').setZone(timezone);
        const relativeToday = DateTime.now().startOf('day').setZone(timezone);
        const relativeTomorrow = DateTime.now().plus({days:1}).startOf('day').setZone(timezone);
        const afterThat  = DateTime.now().plus({days:2}).startOf('day').setZone(timezone);

        if(localDate < relativeToday){
          return this.translateService.instant('DAYS.YESTERDAY');
        } else if(localDate > relativeToday && localDate < relativeTomorrow){
          return this.translateService.instant('DAYS.TODAY');
        } else if(localDate > relativeTomorrow && localDate < afterThat){
          return this.translateService.instant('DAYS.TOMORROW');
        } else {
          return localDate.setLocale(locale).toFormat('DD');
        }
      }
    }
    return ''
  }
}
