import { Component, Input } from '@angular/core';
import { ReductionPlan } from '../../../models/reduction-plan';

@Component({
  selector: 'app-reduction-plan',
  templateUrl: './reduction-plan.component.html',
  styleUrls: ['./reduction-plan.component.scss'],
})
export class ReductionPlanComponent {

  @Input() plan: ReductionPlan;

}
