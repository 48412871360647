import { isDefined, isNull, WithNulls } from 'common-utils/dist/typescript-utils';
import { parseFactory } from './utils';
export * from './comparator';

export const DEFAULT_LOCAL = 'en_US';
export type PerformanceStatus = 'NO_PERF_NUMBERS' | 'UNDER_PERFORMING' | 'PERFORMING_NEAR_EXPECTATION' | 'PERFORMING' | 'ONLY_TARGET_AVAILABLE' | 'ONLY_PERF_AVAILABLE';

const performanceStatuses: Record<PerformanceStatus, true> = {
  NO_PERF_NUMBERS: true,
  UNDER_PERFORMING: true,
  PERFORMING_NEAR_EXPECTATION: true,
  PERFORMING: true,
  ONLY_TARGET_AVAILABLE: true,
  ONLY_PERF_AVAILABLE: true,
};
export function isPerformanceStatus(value: string): value is PerformanceStatus {
  return !!performanceStatuses[value];
}
export function parsePerformanceStatus(value: string): PerformanceStatus | null {
  return (isPerformanceStatus(value)) ? value : null;
}

export type ProgressStatus = 'BEFORE' | 'DURING' | 'AFTER';
export const progressStatuses: Record<ProgressStatus, true> = {
  BEFORE: true,
  DURING: true,
  AFTER: true,
};
export function isProgressStatus(value: string): value is ProgressStatus {
  return !!progressStatuses[value];
}
export function parseProgressStatus(value: string): ProgressStatus | null {
  return (isProgressStatus(value)) ? value : null;
}

export function parseNullable<A, B>(value: A | null, parser: (a: A) => B): B | null {
  return (isNull(value)) ? value : parser(value);
}

export function parseLabel(labels: { [p: string]: string } | string, userLocale: string): string {
  if(!!labels) {
    const label: string = (labels[userLocale]) ? labels[userLocale] : labels[DEFAULT_LOCAL] ? labels[DEFAULT_LOCAL] : labels[Object.keys(labels)[0]];
    return (!!label && label.indexOf('\\') > -1) ? label.replace('\\', '') : label;
  } else {
    return '';
  }

}

export interface WithId { id: string; }
export const parseId = parseFactory<WithId>('id');

export interface WithLabel { display_label: string; }

export interface WithUomValue { uom: string; value: number; }
export const parseUomValue = parseFactory<WithNulls<WithUomValue>>('uom', 'value');

export interface WithPerformancePercent { status: PerformanceStatus; percentage: number; }
export const parseStatusPercent = parseFactory<WithNulls<WithPerformancePercent>>('status', 'percentage');

export interface WithDatetime { utc: string; program_locale: string; }
export const parseDatetime = parseFactory<WithNulls<WithDatetime>>('utc', 'program_locale');

export interface WithShortName { name: string; short: string | null; }

export interface MetaModel<T> {
  readonly model: T;
}

export abstract class Collection<T> {
  protected abstract update(existing: T, incoming: T): T;
  protected abstract index(target: T): string;
  protected merge(existings: T[], incomings: T[]): T[] {
    const existingMap = existings.reduce((a, existing) => {
      a[this.index(existing)] = existing;
      return a;
    }, {} as { [id: string]: T });
    return incomings.map((incoming) => {
      const existing = existingMap[this.index(incoming)];
      return (isDefined(existing)) ? this.update(existing, incoming) : incoming;
    });
  }
}
