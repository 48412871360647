import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APIData } from 'common-utils/dist/models/api';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ContactDetails } from '../models/contact-details';
import { EventNode } from '../models/event-node';
import { ApiEndpointsService } from './api-endpoints.service';
import { CookieService } from 'ngx-shared-services';
import { NodePerformance } from '../models/node-performance';
import { MessageService } from 'primeng/api';

@Injectable({ providedIn: 'root' })
export class ContactDetailsService {

  constructor(
    private http: HttpClient,
    private apiService: ApiEndpointsService,
    private cookieService: CookieService,
    private toaster: MessageService
  ) {}

  getContactDetails(node: EventNode): Observable<ContactDetails[]> {
    const { emsUrl } = this.apiService;
    const session = this.cookieService.getCookie('enoc_session');
    const headers = new HttpHeaders().append('enoc_session', session);

    return this.http.get<APIData<ContactDetails[]>>(`${emsUrl}/v1/event_node/site/${node.site.id}/contacts`, { withCredentials:true }).pipe(
      map(({ data }) => data),
      catchError((err) => { // no need to retry, we're already in a polling loop
        console.log(err);
        this.toaster.add({key: 'connection-error', severity: 'error', sticky: false});
        return of<ContactDetails[]>([]);
      }),
    );
  }
}
