import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {environment} from "../../environments/environment";
import { CookieService } from 'ngx-shared-services';
import { catchError, map, mergeMap, retry, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { forkJoin, from, Observable, of, ReplaySubject, Subject } from 'rxjs';
//import * as moment from 'moment-timezone/builds/moment-timezone-with-data-1970-2030.min';
import { DateTime } from 'luxon'
import { Registration } from '../models/interfaces/registration';
import { forEach } from '@angular-devkit/schematics';
import { flattenDisplayLabel } from './format.service';
import { Event } from '../models/interfaces/event';
import { EventNode } from '../models/interfaces/event-node';
import { Product } from '../models/interfaces/product';
import { UserService } from './user.service';



@Injectable({
  providedIn: 'root'
})
export class DispatchScheduleService {
  eps_url;
  ems_url;
  events;
  params;
  headers;
  eventNodes$ = new Subject<EventNode[]>();
  eventsFinished$ = new Subject<Event[]>();
  fetchingEvents;
  productsMap = {};
  eventsMap = {};
  registrationsMap = {};
  allEventNodes = [];
  todayNodes = [];
  futureNodes = [];
  allowedEventTypes = ['OFFICIAL', 'AUDIT', 'VOLUNTARY', 'READINESS_TEST', 'STANDBY'];
  dateFormat="MM/DD/YYYY"

  constructor(private http: HttpClient, private cookieService: CookieService, private US: UserService) {
    this.ems_url = environment.brands.enelx.emsUrl;
    this.eps_url = environment.brands.enelx.baseUrl;
    const session = this.cookieService.getCookie('enoc_session');
    this.headers = new HttpHeaders().append('enoc_session', session);
    this.params = new HttpParams().set('hierarchy', 'true');
  }


  getEvents(){
    this.allEventNodes = [];

    this.http.get<any>(`${this.ems_url}/v1/eps_events`, { params: {hierarchy: true, show_all_event_types: true, withWorkflow:true}, withCredentials:true }).pipe(retry(2), map(({data}) => data)).subscribe(
      (resp: Event[])=>{
        this.events = _.filter(resp, (event)=>{
          return this.allowedEventTypes.includes(event.event_action_type)
        })
        this.getProducts();
        const locale = this.US.user?.default_locale || 'en_US'
        this.events.forEach((event: Event)=>{
          event.program_name = flattenDisplayLabel(locale, event.program_name);
          this.eventsMap[event.event_id] = event;
          this.allEventNodes = [...this.allEventNodes, ...event.event_nodes]
        })
        //this.getRegistrations();
      },
      (error)=>{

      }
    )
  }

  clear(){
    this.eventsMap = {};
    this.allEventNodes = [];
  }

  getProducts(){
    const locale = this.US.user?.default_locale || 'en_US'
    const uniqueProdIds: Observable<any[]> = of((_.map(_.uniqBy(this.events, 'product_id'), 'product_id')));
    uniqueProdIds.pipe(
      mergeMap((ids)=>{
        if(!ids.length) {
          return of<any[]>([]);
        }
        return forkJoin(
          ids.map((id) => {
              if(id) {
                if(this.productsMap[id]) {
                  return from(new Promise((resolve) => resolve(this.productsMap[id]))) ;
                } else {
                  return this.http.get<any>(`${this.ems_url}/v1/product/${id}`, {withCredentials: true}).pipe(
                    map(({data}) => data),
                    map((resp: Product) => {
                      this.productsMap[id] = resp;
                      return resp;
                    }),
                    catchError((err)=> {
                      console.log(err);
                      return from(new Promise<void>((resolve) => resolve())) ;
                    }),
                  );
                }

              } else {
                return from(new Promise((resolve) => resolve(null))) ;
              }

            },
          ));
      })
    ).subscribe(
      (resp)=>{
        this.events.forEach((event)=>{
          event.product = this.productsMap[event.product_id]
        })
        this.allEventNodes.forEach((node: EventNode)=>{
         // node.registration = this.registrationsMap[node.registration_id];
          node.event = this.eventsMap[node.event_id];
          node.locale = locale;
        })
        this.eventNodes$.next(this.allEventNodes)
      }
    )

  }

  getRegistrations(){
    const uniqueProdIds: Observable<any[]> = of((_.map(_.uniqBy(this.events, 'product_id'), 'product_id')));

    uniqueProdIds.pipe(
      mergeMap((ids)=>{
        if(!ids.length) {
          return of<any[]>([]);
        }
        return forkJoin(
          ids.map((id) => {
              return this.http.get<any>(`${this.ems_url}/v1/products/${id}/registrations?statuses=ACTIVE`, {withCredentials: true}).pipe(
                map(({data}) => data),
                map((resp: Registration[]) => {
                  resp.forEach((reg)=>{
                    this.registrationsMap[reg.id] = reg;
                  })

                  return resp;
                }),
                catchError((err)=> {
                  console.log(err);
                  return from(new Promise<void>((resolve) => resolve())) ;
                }),
              );
            },
          ));
      })
    ).subscribe(
      (resp)=>{
        const locale = this.US.user?.default_locale || 'en_US'
        this.allEventNodes.forEach((node: EventNode)=>{
          node.registration = this.registrationsMap[node.registration_id];
          node.event = this.eventsMap[node.event_id];
          node.locale = locale;
        })
        //this.groupNodes();
        this.eventNodes$.next(this.allEventNodes)
      }
    )
  }


}
