<div [ngClass]="(isMobile) ? 'dispatch-list mobile-format' : 'dispatch-list'">
  <div class="fake-top-bar">
    <app-dispatch-list-toggle></app-dispatch-list-toggle>
    <span class="close-button fa fa-times" (click)="close()"></span>
  </div>

  <div class="scrolly">
    <div class="sort-by">
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>{{ 'DISPATCH-LIST.SORT-BY.LABEL' | translate }}</mat-label>
        <mat-select  #sortSelect (selectionChange)="onChangeSort(sortSelect.value)"[value]="selectedOption">
          <mat-option *ngFor="let token of sortOptions" [value]="token.key">
            {{ token.display_label | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="current-dispatch-list">
      <div class="section-header">{{ 'DISPATCH-LIST.DISPATCH-STATUS.CURRENT' | translate }}</div>
      <app-event-card *ngFor="let event of duringEvents" [event]="event"></app-event-card>
    </div>
    <div class="upcoming-dispatch-list">
      <div class="section-header">{{ 'DISPATCH-LIST.DISPATCH-STATUS.UPCOMING' | translate }}</div>
      <app-event-card *ngFor="let event of beforeEvents" [event]="event"></app-event-card>
    </div>
    <div class="completed-dispatch-list">
      <div class="section-header">{{ 'DISPATCH-LIST.DISPATCH-STATUS.COMPLETED' | translate }}</div>
      <app-event-card *ngFor="let event of afterEvents" [event]="event"></app-event-card>
    </div>

  </div>
</div>
