import { Injectable } from '@angular/core';
import { ApiTime } from 'common-utils/dist/models/time';
import * as moment from 'moment';
import { CookieService } from 'ngx-shared-services';

export type FormatTimeInput = number | string | Date | moment.Moment | ApiTime;

@Injectable({ providedIn: 'root' })
export class FormatService {

  private defaultLocale: string;

  constructor(
    private cookieService: CookieService,
  ) {
    this.defaultLocale = moment().locale(false).locale();
  }

  time(input: FormatTimeInput | null, momentFormat = 'LT'): string | null {
    if (input === null) { return null; }
    const locale = this.cookieService.getI18NLocale();
    const momentTime = toMoment(input);
    try {
      const parsedLocale = momentTime.locale(locale).locale();
      const isInvalidLocale = (parsedLocale === this.defaultLocale) && (!locale.startsWith(parsedLocale));
      if (isInvalidLocale) throw new Error('Invalid locale');
      return momentTime.format(momentFormat);
    } catch (e) {
      console.error('Error formatting date', input, momentFormat, locale);
      return null;
    }
  }

  number(input: number | null, maximumFractionDigits = 0): string | null {
    if (input === null) { return null; }
    const locale = this.cookieService.getI18NLocale();
    try {
      return new Intl.NumberFormat(locale, { maximumFractionDigits }).format(input);
    } catch (e) {
      console.error('Error formatting number', input, maximumFractionDigits, locale);
      return null;
    }
  }
}

export function toMoment(input: FormatTimeInput): moment.Moment {
  return (typeof input === 'string' || typeof input === 'number') ?
    toMoment(new Date(input)) :
    (input instanceof Date) ?
      moment(input) :
      (input instanceof ApiTime) ?
        moment(input.localDate) :
        input;
}

export function flattenDisplayLabel(locale, displayLabels) {
  const defaultBackupLocale = 'en_US';
  if (displayLabels == null) {
    return '';
  } else if (typeof displayLabels !== 'object') {
    return displayLabels;
  } else if (Object.keys(displayLabels).length === 0) {
    return '';
  }
  if (locale != null) {
    if (displayLabels[locale] != null) {
      return displayLabels[locale];
    }
  }
  if (!!displayLabels[defaultBackupLocale]) {
    return displayLabels[defaultBackupLocale];
  } else {
    // default isn't even here so return the first label
    return displayLabels[Object.keys(displayLabels)[0]];
  }
}
