import {Component} from '@angular/core';
import {DispatchExpandService} from '../../services/dispatch-expand.service';
import {Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {NativeService} from '../../services/native.service';
import { EventsApiService } from '../../services/events-api.service';

@Component({
  selector: 'app-dispatch-list-toggle',
  templateUrl: './dispatch-list-toggle.component.html',
  styleUrls: ['./dispatch-list-toggle.component.scss'],
})
export class DispatchListToggleComponent {

  readonly active$: Observable<boolean>;
  readonly isMobile: boolean;
  noData$: Observable<boolean>;

  constructor(
    private dispatchExpandService: DispatchExpandService,
    private nativeService: NativeService,
    private eventService: EventsApiService,
  ) {
    this.isMobile = this.nativeService.isMobileDevice();
    const {events$} = this.eventService;
    this.active$ = events$.pipe(
      map((events) => events.some((event) => event.progress_status === 'DURING')),
      shareReplay(1),
    );
    this.noData$ = events$.pipe(
      map((events) => !events.length),
      shareReplay(1),
    );
  }

  toggle(): void {
    this.dispatchExpandService.toggle();
  }

}
