<div class="event-node row" [ngClass]="highlightClass$ | async" (click)="onClick()" (onHover)="onHover($event)">
  <div class="column performance">
    <div class="performance-indicator" [ngClass]="indicatorClass"></div>
    <span ellipsisTooltip>{{ perfValue }}</span>
  </div>
  <div class="column location">
    <span ellipsisTooltip>{{ location }}</span>
  </div>
  <div class="column reduction">
    <span ellipsisTooltip>{{ reduction }}</span>
  </div>
</div>
