import {Component, OnInit} from '@angular/core';
import { combineLatest, Observable, Subject, of } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, startWith, switchMap, take, tap, filter } from 'rxjs/operators';
import { ContactDetails } from '../../models/contact-details';
import { ReductionPlan } from '../../models/reduction-plan';
import { ContactDetailsService } from '../../services/contact-details.service';
import { DispatchSelectService } from '../../services/dispatch-select.service';
import {NativeService} from '../../services/native.service';
import { PerformanceApiService } from '../../services/performance-api.service';
import { ReductionPlanService } from '../../services/reduction-plan.service';
import { DispatchGraphViewModel, factory } from './graph-interval-data/graph-interval.viewModel';
import { EventNode } from '../../models/event-node';
import { EventsApiService } from '../../services/events-api.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit{

  readonly loading$: Observable<boolean>;
  readonly hasData$: Observable<boolean>;
  readonly viewModel$: Observable<DispatchGraphViewModel>;
  readonly contactDetails$: Observable<ContactDetails[]>;
  readonly reductionPlan$: Observable<ReductionPlan>;
  readonly isMobile: boolean;

  private _performanceLoading$ = new Subject<boolean>();
  private _contactDetailsLoading$ = new Subject<boolean>();
  private _reductionPlanLoading$ = new Subject<boolean>();

  dataLoaded = false;
  constructor(
    private dispatchSelectService: DispatchSelectService,
    private performanceService: PerformanceApiService,
    private contactDetailsService: ContactDetailsService,
    private reductionPlanService: ReductionPlanService,
    private nativeService: NativeService,
    private eventService: EventsApiService,
  ) {
    this.isMobile = this.nativeService.isMobileDevice();
    const { events$ } = this.eventService;
    const { selected$ } = this.dispatchSelectService;

    this.hasData$ = events$.pipe(
      map((events) => !!events),
      shareReplay(1),
    );

    this.loading$ = this.hasData$.pipe(
      switchMap((hasData) => hasData ? combineLatest(this._performanceLoading$, this._contactDetailsLoading$, this._reductionPlanLoading$).pipe(
        map(([performanceLoading, contactDetailsLoading, reductionPlanLoading]) => performanceLoading || contactDetailsLoading || reductionPlanLoading),
      ) : of(false)),
      startWith(true),
      distinctUntilChanged(),
      shareReplay(1),
    );

    this.viewModel$ = selected$.pipe(
      filter<EventNode>((node) => !!node),
      tap(() => this._performanceLoading$.next(true)),
      switchMap((node) => this.performanceService.getPerformanceData(node).pipe(map((intervals) => factory(node, intervals)))),
      tap(() => this._performanceLoading$.next(false)),
      shareReplay(1),
    );

    this.contactDetails$ = selected$.pipe(
      filter<EventNode>((node) => !!node),
      tap(() => this._contactDetailsLoading$.next(true)),
      switchMap((node) => this.contactDetailsService.getContactDetails(node)),
      tap(() => this._contactDetailsLoading$.next(false)),
      shareReplay(1),
    );

    this.reductionPlan$ = selected$.pipe(
      filter<EventNode>((node) => !!node),
      tap(() => this._reductionPlanLoading$.next(true)),
      switchMap((node) => this.reductionPlanService.getReductionPlan(node)),
      tap(() => this._reductionPlanLoading$.next(false)),
      shareReplay(1),
    );

    this.viewModel$.pipe(take(1)).subscribe();
    this.contactDetails$.pipe(take(1)).subscribe();
    this.reductionPlan$.pipe(take(1)).subscribe();


    events$.pipe().subscribe(
      (resp) => {
        this.dataLoaded = true;
      }
    )
  }
  ngOnInit(){
    if(this.eventService.events?.length){
      this.eventService.refreshEvents();
    } else {
      this.eventService.getEvents();
    }
  }
}
