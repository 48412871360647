import { Product } from './product';
import { EventNode } from './event-node';



export interface EventAPI {
  event_name: string;
  event_name_prefix: string;
  event_id: string;
  operator_id: string;
  operator_name: string;
  program_id: string;
  program_name: string;
  product_id: string;
  product_name: string;
  portfolio_id: string;
  //requested_obligations: Array<RequestedObligation>;
  obligation_uom: string;
  program_name_short: string;
  event_performance_percent: number;
  event_progress_status: string;
  sum_expected_capacity_value: number;
  sum_expected_capacity_uom: string;
  average_performance_value: number;
  minimum_performance_value: number;
  maximum_performance_value: number;
  average_performance_uom: string;
  average_performance_status: string;
  last_current_performance_value: number;
  last_current_performance_uom: string;
  last_current_performance_status: string;
  num_participating_units: number;
  program_time_zone_abbr: string;
  event_start_dttm_utc: string;
  min_start_program_offset_min: number;
  max_end_program_offset_min: number;
  event_start_dttm_locale: string;
  event_end_dttm_utc: string;
  event_end_dttm_locale: string;
  deleted: boolean;
  performanceData: any;
  event_action_type: string;
  event_action_type_display_label:string;
  notification_time_utc: any;
  operator_display_label:string;
  ems_program_display_label:string;
  product_display_label:string;
  portfolio_display_label:string;
  event_type:string;
  event_type_display_label:string;
  created_by:string;
  created_by_name:string;
  created_dttm:string;
  cancelled: boolean;
  event_paused: boolean;
  non_coincident_minimum_performance_value: number;
  non_coincident_maximum_performance_value: number;
  source_system_type: string;
  event_node_statistics: EventNodeStatistics;
  status:string;
  full_time_zone:string;
  workflow_status: any;
  total_availability:number;
  event_nodes: EventNode[];
  expected_capacity_value;
  //
  // custom_tasks: Array<CustomTask>;
  // selectedInGrid: boolean;
  // obligation:number;
  //
  //

}

export interface Event extends EventAPI {

  product: Product;
}

export interface EventNodeStatistics {
  total_event_nodes: number;
  active_event_nodes: number;
  pending_event_nodes: number;
  opted_out_event_nodes: number;
  start_dttm_utc: string;
  end_dttm_utc: string;
  site_display_label: object;
  expected_capacity_value: number;
  registered_capacity_value: number;
}

export enum KeyPerformanceMetric {AVERAGE = 'AVERAGE', MINIMUM = 'MINIMUM', MAXIMUM = 'MAXIMUM'}
