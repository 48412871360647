import { Component, Input, OnInit } from '@angular/core';
import { CookieService } from 'ngx-shared-services';
import { Event as EPSEvent } from '../../../models/event';
import { NativeService } from '../../../services/native.service';
import { HoverEvent } from 'ngx-ux-components';
import { DispatchSelectService } from '../../../services/dispatch-select.service';
import { EventsApiService } from '../../../services/events-api.service';
import { orString } from '../../../models/shared/utils';
import { PercentPipe } from '@angular/common';
import { PerformanceClassPipe } from '../../../pipes/performance-class.pipe';
import { PerformanceShowPipe } from '../../../pipes/performance-show.pipe';
import { TranslateService } from '@ngx-translate/core';
import { EventWindowPipe } from '../../../pipes/event-window.pipe';
import { EventTimerPipe } from '../../../pipes/event-timer.pipe';
import { filter, map, shareReplay, startWith } from 'rxjs/operators';
import { EventNode } from '../../../models/event-node';
import { combineLatest, Observable, Subject } from 'rxjs';
import { EventListService } from '../../../services/event-list.service';
import { DispatchExpandService } from '../../../services/dispatch-expand.service';

interface PerformanceInfo {
  show: boolean;
  class: string;
  label: string;
}

@Component({
  selector: 'app-event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss']
})

export class EventCardComponent implements OnInit {

  @Input() event: EPSEvent;
  performance: PerformanceInfo;
  program;
  sites;
  reductionVal;
  startAndEndTime;
  timeFromNow;
  fetchingNodes = false;
  locale;
  readonly isMobile: boolean;
  chevron = {show: false, direction: null};
  highlightedClass$: Observable<string>;
  nodes = [];
  get expanded() { return this._expanded; }
  set expanded(value: boolean) {
    this._expanded = value;
    this.setChevron();
  }
  private _expanded: boolean;
  private _hovered$ = new Subject<boolean>();
  constructor(
    private dispatchSelectService: DispatchSelectService,
    private nativeService: NativeService,
    private eventsService: EventsApiService,
    private percentPipe: PercentPipe,
    private performanceClassPipe: PerformanceClassPipe,
    private performanceShowPipe: PerformanceShowPipe,
    private translate: TranslateService,
    private eventWindowPipe: EventWindowPipe,
    private eventTimerPipe: EventTimerPipe,
    private listService: EventListService,
    private dispatchExpandService: DispatchExpandService,
    private cookieService: CookieService

  )
  {
    this.isMobile = this.nativeService.isMobileDevice();
    this.locale = this.cookieService.getI18NLocale();
    const selected$ = this.dispatchSelectService.selected$.pipe(
      filter<EventNode>((node) => !!node),
      map(({ event }) => event === this.event),
      startWith(false),
    );
    const hovered$ = this._hovered$.pipe(
      startWith(false),
    );
    const highlighted$ = combineLatest(hovered$, selected$).pipe(
      map(([hovered, selected]) => hovered || selected),
    );
    this.highlightedClass$ = highlighted$.pipe(
      map((highlighted) => (highlighted) ? `${this.performance.class}-light` : ''),
      shareReplay(1),
    );

    const { eventUpdate$ } = this.eventsService;
    eventUpdate$.pipe().subscribe(
      (resp) => {
        if(this.event && this.event.id == (resp as EPSEvent).id) {
          this.event = resp
          this.setPerformanceAndLabels();
        }
      }
    )
  }

  ngOnInit() {
    this.nodes = this.event.event_nodes;
    this.expanded = this.listService.getEventExpanded(this.event.id);
    this.setPerformanceAndLabels()
  }

  private setPerformanceAndLabels(): void {
    const show = this.performanceShowPipe.transform(this.event);
    const styleClass = this.performanceClassPipe.transform(this.event);
    const label = orString(this.percentPipe.transform(this.event.performance.percentage, '0.0-0'), '?');
    this.performance = { show, class: styleClass, label };

    this.program = this.event.program.name;
    this.sites = (this.event.len_total_event_nodes === 1) ? this.event.site_display_label : this.translate.instant('DISPATCH-LIST.DISPATCH-CARD.SITES', { length: this.event.len_total_event_nodes });
    this.reductionVal = this.translate.instant('DISPATCH-LIST.DISPATCH-CARD.REDUCTION', {
      uomValue: this.translate.instant('UOMVALUE', this.event.sum_expected_capacity),
    });
    this.startAndEndTime = this.eventWindowPipe.transform(this.event.model.event_start_dttm_utc, this.event.model.event_end_dttm_utc, this.event.product?.timezone, this.event.program_time_zone_abbr, 'relative');
    this.timeFromNow = orString(this.eventTimerPipe.transform(this.event), '?');
  }

  onClick() {
    if(!this.fetchingNodes) {
      if(this.event.event_nodes && this.event.event_nodes.length) {
        this.nodes = this.event.event_nodes;
        if(this.event.event_nodes.length === 1) {
          this.dispatchSelectService.select(this.event.event_nodes[0]);
          if(this.isMobile)this.dispatchExpandService.toggle(false);
        } else {
          this.expanded = !this.expanded;
        }
        this.listService.setEventExpanded(this.event.id, this.expanded);
      } else {
        this.fetchingNodes = true;
        this.eventsService.getEventNodes(this.event).subscribe(
          (resp) => {
            this.fetchingNodes = false;
            this.nodes = this.event.event_nodes;
            if(this.nodes.length){
              this.onClick();
            }
          },
        );
      }
    }
  }

  onHover({ hovering }: HoverEvent): void {
    this._hovered$.next(hovering);
  }

  private setChevron(): void {
    const show = this.event.len_total_event_nodes > 1;
    this.chevron = (show) ? {
      show,
      direction: (this.expanded) ? 'up' : 'down',
    } : {
      show,
      direction: null,
    };
  }
}
