import { ApiTime } from 'common-utils/dist/models/time';
import { WithNulls } from 'common-utils/dist/typescript-utils';
import { MetaModel, parseUomValue, WithUomValue } from './shared';

export interface NodePerformanceAPI {
  event_node_id: string;
  site_id: string;
  event_id: string;
  interval_dttm_utc: string;
  interval_dttm_utc_program_locale: string;
  metered_value: number | null;
  metered_uom: string | null;
  baseline_value: number | null;
  baseline_uom: string | null;
  target_value: number | null;
  target_uom: string | null;
  adjusted_baseline_value: number | null;
  performance_value: number;
}

export interface NodePerformance extends MetaModel<NodePerformanceAPI> {
  event_node_id: string;
  site_id: string;
  event_id: string;
  interval: ApiTime;
  metered: WithNulls<WithUomValue>;
  baseline: WithNulls<WithUomValue & { adjusted: number; }>;
  target: WithNulls<WithUomValue>;
  performance: number;
}

export function parse(model: NodePerformanceAPI): NodePerformance {
  const { event_node_id, site_id, event_id } = model;
  return {
    model,
    event_node_id,
    site_id,
    event_id,
    interval: new ApiTime(model.interval_dttm_utc),
    metered: parseUomValue(model, 'metered'),
    baseline: { ...parseUomValue(model, 'baseline'), adjusted: model.adjusted_baseline_value },
    target: parseUomValue(model, 'target'),
    performance: model.performance_value,
  };
}
