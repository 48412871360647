import { Component, Input, OnInit } from '@angular/core';
import { EventListService } from '../../../../services/event-list.service';
import { Event } from '../../../../models/event';
import { animate, style, transition, trigger } from '@angular/animations';
import * as _ from 'lodash'

type ColumnSortState = {
  isActive: false;
  direction: null;
} | {
  isActive: true;
  direction: 'up' | 'down';
};

type ColumnSortStateMap = {
  [column in 'performance' | 'location' | 'reduction']: ColumnSortState;
};

@Component({
  selector: 'app-nodes-list',
  templateUrl: './nodes-list.component.html',
  styleUrls: ['./nodes-list.component.scss'],
  animations: [
    trigger('openClose', [
      transition(':enter', [
        style({ height: 0, padding: 0 , overflow: 'hidden'}),
        animate('200ms 0ms ease-in'),
      ]),
      transition(':leave', [
        style({ overflow: 'hidden' }),
        animate('200ms ease-out', style({ 'height': 0, 'padding-top': 0, 'padding-bottom': 0, 'padding-right': 0, 'padding-left': 0 })),
      ]),
    ]),
  ],
})
export class NodesListComponent implements OnInit {

  @Input() event: Event;
  @Input() eventNodes;
  sortedColumn = {name: 'performance', direction: 'up'};
  constructor(private listService: EventListService) { }

  ngOnInit() {
    this.sortedColumn = this.listService.getNodesColumnSort(this.event.id);
    this.doSort();
  }


  sortBy(column) {
    let direction = 'up';
    if(this.sortedColumn.name === column) {
      direction = this.sortedColumn.direction === 'up' ? 'down' : 'up'
    }
    this.sortedColumn.name = column;
    this.sortedColumn.direction = direction;

    this.doSort();
  }

  doSort() {
    switch (this.sortedColumn.name) {
      case 'performance':
        this.eventNodes = _.sortBy(this.eventNodes, ['performance.percentage']);
        break;
      case 'location':
        this.eventNodes = _.sortBy(this.eventNodes, ['site.display_label']);
        break;
      case 'reduction':
        this.eventNodes = _.sortBy(this.eventNodes, ['expected_capacity.value']);
        break;
    }
    if(this.sortedColumn.direction == 'down') {
      this.eventNodes = _.reverse(this.eventNodes);
    }

    this.listService.setNodesColumnSort(this.event.id, this.sortedColumn.name, this.sortedColumn.direction)
  }

}
